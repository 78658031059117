import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import axios from 'axios';
import {
  MRT_BottomToolbar,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';

import PreviewIcon from '@mui/icons-material/Preview';
import { Box, Button, IconButton } from '@mui/material';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

export default function Table_Niveau({P_Niveau}) {
  const [NiveauData, setNiveauData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        const response = await axios.get(host.Backend+'Niveau/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setNiveauData(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
    const columns = useMemo(
        () => [
            {
              accessorKey: 'nom',
              header: 'Niveau',
              size: 150,
            },
          {
            accessorKey: 'created_at',
            header: 'Date d\'enregistrement',
            size: 150,
          },
        ],
        [],
      );
    
      const data = NiveauData ? NiveauData : [];

      const handleDownloadRows = (selectedRows) => {
        console.log('Selected Rows:', selectedRows);
        // Implement your download functionality with the selected rows
        // ...
      };

    
      const togglePreview = () => {
        // Toggle the selected state
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.length > 0 ? [] : selectedRows
        );
      };

      const handleExportRows = (rows) => {
        const doc = new jsPDF();
        
        // Définissez les colonnes que vous souhaitez exporter
        const exportColumns = [
          'nom',
          'created_at'
          // ... ajoutez d'autres colonnes selon vos besoins
        ];
      
        // Filtrer les données pour inclure uniquement les colonnes sélectionnées
        const filteredTableData = rows.map((row) => {
          const filteredRow = {};
          exportColumns.forEach((column) => {
            filteredRow[column] = row.original[column];
          });
          return Object.values(filteredRow);
        });
      
        // Utilisez les noms des colonnes sélectionnées comme en-têtes
        const tableHeaders = exportColumns.map((column) => columns.find((c) => c.accessorKey === column).header);
     
        autoTable(doc, {
          head: [tableHeaders],
          body: filteredTableData,
        });
      
        doc.save('Niveau.pdf');
      };
      

      return <MaterialReactTable 
      
      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering
      enableRowSelection
      enableSelectAll={true}

      initialState={{ showColumnFilters: false, showGlobalFilter: true }}

      localization={MRT_Localization_FR}
      
      renderTopToolbarCustomActions={({ table }) => (
        <Box sx={{ display: 'flex', gap: '1rem', p: '2px' }}>
  
          <IconButton 
            onClick={
              P_Niveau
            }
            vari>
          <AddBoxIcon  />
          
        </IconButton>
        <IconButton onClick={togglePreview} variant="contained" color="lightblue">
              <PreviewIcon style={{ color: selectedRows.length > 0 ? 'green' : 'gray' }} />
            </IconButton>
  
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<PrintIcon />}
          >
           
          
          </Button>
        </Box>
      )}
  
      
      />;
}
