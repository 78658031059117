import React from 'react';

const Aside = ({Asider , Menu ,Dashboard, Rapports ,Accueil,IsRapport, Eleve ,Enseignant, Emploi , Notes , Biblio , Reglage, Comptabilite , Admin ,Com , Emmergements,Cours , IsEleve , isEnseignant , IsCours , isAdmin , Isemmergements , isEmploi , isNotes , isComptabilite , isReglage , Iscom}) => {


  return (
<aside className={`layout-menuu menu-vertical menu bg-menu-theme ${Asider ? 'hidden' : ''}`} style={{overflowY: 'hidden'}}>
             <div className="app-brand demo">
               <a className="app-brand-link">
                 <span className="app-brand-logo demo">
                   <img src="../assets/img/favicon/favicon.png" />
                 </span>
                 <span className="app-brand-text demo menu-text fw-bolder ">avane 2.0</span>

               </a>
             </div>
             {Menu && (
  <a
    onClick={Menu}
    className=" text-large ms-auto  bg-primary mb-1  rounded-circle p-2 phoneview "
    style={{ width: '40px', height: '40px'}}
  >
    <i className="bx bx-chevron-left bx-sm text-light align-middle"></i>
  </a>
)}

             <div className="menu-inner-shadow"></div>
   
             <ul className="menu-inner py-1">
   
               <li className={`menu-item ${Accueil && "active"}  `}>
                 <a onClick={Dashboard}  className="menu-link">
   
                 
                 <i class="bi bi-border-all"></i>            
    &nbsp; 
   
                   <div data-i18n="Analytics"> Dashboard</div>
                 </a>
               </li>
               <li className={`menu-item ${IsEleve && "active"} `}>
                 <a onClick={Eleve} className="menu-link ">
   
                 <i class="bi bi-people"></i> &nbsp;
   
                   <div data-i18n="Account Settings">Elèves</div>
                 </a>
   
               </li>
               <li className={`menu-item ${isEnseignant && "active"} `}>
                 <a  onClick={Enseignant} className="menu-link ">
   
                 <i class="bi bi-people"></i> &nbsp;
                   <div data-i18n="Account Settings">Enseignants</div>
                 </a>
               </li> 
               <li className={`menu-item ${isAdmin && "active"} `}>
                 <a  onClick={Admin} className="menu-link ">
   
                 <i class="bi bi-person-gear"></i> &nbsp;
                   <div data-i18n="Account Settings">Administrateurs</div>
                 </a>
               </li>

               <li className={`menu-item ${IsCours && "active"} `}>
                 <a  onClick={Cours} className="menu-link ">
                 <i class="bi bi-mortarboard  "></i>&nbsp;
                   <div data-i18n="Account Settings">Cours</div>
                 </a>
               </li>
               <li className={`menu-item ${Isemmergements && "active"} `}>
                 <a  onClick={Emmergements} className="menu-link ">
   
                 <i class="bi bi-database-add"></i> &nbsp;
                   <div data-i18n="Account Settings">Emmergements</div>
                 </a>
               </li>
               <li className={`menu-item ${isEmploi && "active"} `}>
                 <a onClick={Emploi} className="menu-link" >
                 <i class="bi bi-calendar-check"></i> &nbsp;
                   <div data-i18n="Authentications">Calendrier scolaire</div>
                 </a>
               </li>
               <li className={`menu-item ${isNotes && "active"} `}>
                 <a onClick={Notes} className="menu-link ">
                 <i class="bi bi-journal-check"></i> &nbsp;
                   <div data-i18n="Misc"> Notes</div>
                 </a>
                 </li>
                 <li className={`menu-item ${Iscom && "active"} `}>
                 <a  onClick={Com} className="menu-link ">
   
                 <i class="bi bi-calculator"></i> &nbsp;
                   <div data-i18n="Account Settings">Discipline</div>
                 </a>
               </li>
   

               <li className={`menu-item ${isComptabilite && "active"} `}>
                 <a  onClick={Comptabilite} className="menu-link ">
   
                 <i class="bi bi-calculator"></i> &nbsp;
                   <div data-i18n="Account Settings">Comptabilite</div>
                 </a>
               </li>

               
   
                 
               <li className={`menu-item ${IsRapport && "active"} `}>
                 <a onClick={Rapports} className="menu-link ">
                 <i class="bi bi-printer"></i> &nbsp;
                   <div data-i18n="Extended UI">Rapport</div>
                 </a>
               </li>
   
               <li className={`menu-item ${isReglage && "active"} `}>
                 <a onClick={Reglage} className="menu-link ">
                 <i class="bi bi-building-gear"></i> &nbsp;
                   <div data-i18n="Extended UI">Reglages</div>
                 </a>
               </li>
             </ul>
           </aside>
  );
}

export default Aside;