import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import interactionPlugin from '@fullcalendar/interaction';
import Program from './Emploi/Program';

export default function Emploi1() {
  const [calendrier  , setcalendrier]= useState(true)
  const [Programation , setProgramation] = useState(false)
  const program = () =>{
    setProgramation(true)
  
   }
   const close_program = () =>{
    setProgramation(false)
  
   }
  
  return (
    <> 
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Calendrier Académique</h4>

<p>
Plannifier, et visualiser vos cours dans toutes vos classes. 
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={program} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-adobe mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Horaires de cours réguliers</p>
      </div>
    </div>
    <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-algolia mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Événements scolaires et Examens</p>
      </div>
    </div>
    <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-algolia mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Planning des cours et des matières</p>
      </div>
    </div>
    <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-algolia mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Gestion des devoirs et des évaluations</p>
      </div>
    </div>
 
    {calendrier && <div className="container">
      <div className="row">
        <div className="card p-3 col overflow-x-scroll" >
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            events={[
              { title: 'Événement 1', date: '2023-12-01', time: '08:00' },
              { title: 'Événement 3', date: '2023-12-01', time: '08:00' },
              { title: 'Événement 4', date: '2023-12-01', time: '08:00' },
              { title: 'Événement 4', date: '2023-12-01', time: '08:00' },
              { title: 'Événement 5', date: '2023-12-01', time: '08:00' },
              { title: 'Événement 4', date: '2023-12-04', time: '08:00' },
              { title: 'Événement 5', date: '2023-12-04', time: '08:00' },
              { title: 'Événement 2', date: '2024-02-02', time: '12:00' }
            ]}
            locale={frLocale}
            themeSystem="bootstrap5"
            dragScroll={true}
            selectable={true}
            editable={true}
            className="calendar-container" // Ajoute une classe pour le conteneur du calendrier
          />
        </div>
      </div>
    </div> }

  </div>
  
  {Programation && 
<Program
Ouvrir={program}
Fermer = {close_program}
></Program>}


</>


  )
}
