import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

export default function Matiere({Ouvrir, Fermer,P_AllNiveau}) {

    const [formData, setFormData] = useState({
  
      classe_id: '',
      matiere: '',
      sur: '',
      sur_final: ''
  
    });
    const [alert, setAlert] = useState(null);
    const [ClasseAproprie , setClasseAproprie] = useState(null)
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const  handleupClasse = async (e) => {
  
          try {
      
            const response = await axios.get(host.Backend+`Classebyniveau/${e.target.value}/`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
              },
            });
      
            setClasseAproprie(response.data);
          } catch (error) {
            console.error('Erreur lors de la requête API:', error);
          } 
      
  
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log([formData])
      try {
        // Utilisez Axios pour envoyer les données du formulaire au serveur
        const response = await axios.post(host.Backend+'Matiere/', [formData]);
        
        switch (response.status) {
          case 201:
            setAlert({ type: 'success', message: 'Niveau créé avec succès!' });
            break;
          case 400:
            setAlert({ type: 'danger', message: 'Format de texte invalide ou le niveau existe déjà' });
            break;
          default:
            setAlert({ type: 'danger', message: 'Erreur inattendue lors de la création du niveau' });
            break;
        }
      } catch (error) {
        // Gérez les erreurs ici
        console.error('Erreur lors de l\'envoi du formulaire :', error);
      }
    };




  return (
    <Modal
    show={Ouvrir}
    onHide={Fermer}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Matière</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}> 
    <Modal.Body>
    <div class="modal-body">
                <div className="row">
                <div className="col mb-3">
                  <label htmlFor="niveau" className="form-label">Niveau</label>
                  <select className='form-select' value={formData.niveau_id} onChange={(e) => {
                    handleChange(e);
                    handleupClasse(e);
                  }} name='niveau_id'>
                    <option value={null}>Choisir...</option>
                    {P_AllNiveau.map((niveau) => (
                      <option key={niveau.id} value={niveau.id}>
                        {niveau.nom}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col mb-3">
                <label htmlFor="classe" className="form-label">Classe</label>
                <select className='form-select' name='classe_id' onChange={(e) => {
                  handleChange(e);
                }} required>
                  <option value={null}>Choisir...</option>
                  {ClasseAproprie && ClasseAproprie.map((classe) => (
                    <option key={classe.id} value={classe.id}>
                      {classe.classe}
                    </option>
                  ))}
                </select>
              </div>
            </div>
                  <div class="row">
                  <div class="col mb-3">
                    <label for="classe" class="form-label">Matiere</label>
                    <input type='texte' name='matiere' onChange={(e) => {
                        handleChange(e);
                      }} className='form-control' placeholder='' />
                  </div>
                  </div>
                  <div class="row">
                  <div class="col mb-3">
                    <label for="classe" class="form-label">Les notes sont sur ?</label>
                    <select className='form-select'  name='sur' onChange={handleChange} required>     
                     
                  <option value={null}>Choisir...</option>
        <option key={20} value={20}>20</option>
        <option key={10} value={10}>10</option>
        <option key={5} value={5}>5</option>
                    </select>
                  </div>

                  <div class="col mb-3">
                    <label for="classe" class="form-label">Et Finalement ?</label>
                    <select className='form-select'  name='sur_final' onChange={handleChange} required>
                           
                  <option value={null}>Choisir...</option>
                      <option key={20} value={20}>20</option>
                      <option key={30} value={30}>30</option>
                      <option key={40} value={40}>40</option>
                      <option key={50} value={50}>50</option>
                      <option key={60} value={60}>60</option>
                      <option key={70} value={70}>70</option>
                      <option key={80} value={80}>80</option>
                      <option key={90} value={90}>90</option>
                      <option key={100} value={100}>100</option>
                      <option key={110} value={100}>110</option>
                      <option key={120} value={120}>120</option>
                    </select>
                  </div>
                  </div>
                  
               
              </div>
                  
    </Modal.Body>
    <Modal.Footer>
    <button  onClick={Fermer} type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                  Fermer
                </button>
                <button type="submit" class="btn btn-primary">Envoyer</button>
    </Modal.Footer>
    </form>
  </Modal>
  )
}
