import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
export default function Classe({Ouvrir, Fermer, P_AllNiveau}) {

  const [formData, setFormData] = useState({

    classe: '',
    niveau_id: '',
    frais_scolarite: ''

  });
  const [alert, setAlert] = useState(null);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log([formData])
  
    try {
      // Utilisez Axios pour envoyer les données du formulaire au serveur
      const response = await axios.post(host.Backend+'Classe/', [formData]);
      
      switch (response.status) {
        case 201:
          setAlert({ type: 'success', message: 'Niveau créé avec succès!' });
          break;
        case 400:
          setAlert({ type: 'danger', message: 'Format de texte invalide ou le niveau existe déjà' });
          break;
        default:
          setAlert({ type: 'danger', message: 'Erreur inattendue lors de la création du niveau' });
          break;
      }
    } catch (error) {
      // Gérez les erreurs ici
      console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
  };


  return (
    <Modal
    show={Ouvrir}
    onHide={Fermer}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Création de Classe</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
    <Modal.Body>
    <div class="modal-body">
    {alert && (
        <div className={`alert alert-${alert.type}`} role="alert">
          {alert.message}
          </div> )}
      <div class="row">

        <div class="col mb-3">
          <label for="classe" class="form-label">Niveau</label>
          <select  className='form-select' onChange={handleChange} name='niveau_id' required>
            <option value={null}>Niveau...</option>
          {P_AllNiveau.map((niveau) => (
              <option key={niveau.id} value={niveau.id}>
                {niveau.nom}
              </option>
          ))}
          </select>
        </div>
        </div>
        <div class="row">
        <div class="col mb-3">
          <label for="classe" class="form-label">Classe</label>
          <input type='Text' onChange={handleChange} name='classe' className='form-control' placeholder='Nom de la Classe' />
        </div>
        <div class="col mb-3">
          <label for="classe"  class="form-label">Frais de Scolarité</label>
          <input type='number' onChange={handleChange} name='frais_scolarite'  className='form-control' placeholder='En FCFA' />
        </div>
        </div>
        
      
    </div>
                  
    </Modal.Body>

    <Modal.Footer>
    <button  onClick={Fermer} type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                  Fermer
                </button>
                <button type="submit" class="btn btn-primary">Ajouter</button>
    </Modal.Footer>
    </form>
  </Modal>
  )
}
