import React, { useState , useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  IconButton,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

function EvaGest({noteInfo, noteTable}) {
  const [evaluationData, setevaluationData] = useState([]);
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+`EvaGest/${noteInfo.matiere}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });


        setevaluationData(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) =>{
    setevaluationData({ ...evaluationData, [e.target.name]: e.target.value });
  }
  const handleSave = () => {
    setShowModal(true);
  };

  const handleCloseModal = async () => {

    setShowModal(false);
  };

   
  return (
    <div>
        <h2>{noteInfo.matiere}</h2>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Evaluation</TableCell>
              <TableCell>Matiere</TableCell>
              <TableCell>Bareme</TableCell>
              <TableCell style={{ width: 160 }}>Note Mini</TableCell>
              <TableCell style={{ width: 160 }}>Note Max</TableCell>
              <TableCell style={{ width: 160 }}>Nbre Moy</TableCell>
              <TableCell>Moy</TableCell>
              <TableCell>Prt</TableCell>
              <TableCell>Abs</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Classe</TableCell>
              <TableCell>S</TableCell>
              <TableCell>AC</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Editer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.matiere}</TableCell>
                <TableCell>{row.sur}</TableCell>
                <TableCell >{row.note_mini}</TableCell>
                <TableCell>{row.note_max}</TableCell>
                <TableCell>{row.nombre_moyenne}</TableCell>
                <TableCell>{row.moyenne}</TableCell>
                <TableCell>{row.present}</TableCell>
                <TableCell>{row.absents}</TableCell>
                <TableCell>{row.date_evaluation}</TableCell>
                <TableCell>{row.classe} {row.groupe} </TableCell>
                <TableCell>{row.semestre}</TableCell>
                <TableCell>{row.annee_academique}</TableCell>
                <TableCell>{row.is_active}</TableCell>
                <TableCell>
                    <IconButton>
                    <EditIcon />
                    </IconButton>
                </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="primary" onClick={handleSave}>
        Save
      </Button>
       &nbsp;&nbsp;&nbsp;
      <Button variant="danger" onClick={noteTable}>
        Quitter
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Evaluation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  
  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EvaGest;
