import { Button } from 'bootstrap';

import React, { useEffect, useState } from 'react'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import Select from 'react-select'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
import ModalFooter from 'react-bootstrap/esm/ModalFooter';
import ProgressMobileStepper from '../step';
const authToken = localStorage.getItem('authToken');

const Inscription = ({Ouvrir, Fermer , Eleve, AllNiveau , setRDash}) => {
  const [step, setStep] = useState(1);
  const [selectedOption1, setselectedOption1] = useState([]);
  const [InscriptionInfo, setInscriptionInfo] = useState({eleve_id : "" , niveau_id: "" , classe_id: "" , groupe_id: "" });
  const [EleveOption, setEleveOption] = useState([]);
  const [ClasseAproprie , setClasseAproprie] = useState([])

  const  handleupClasse = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Classebyniveau/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setClasseAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };
  useEffect(() => {
    // Assuming Eleve is an array
    const options = Eleve.map((element) => ({
      value: element.id,
      label: `${element.nom} ${element.prenom} ${element.matricule}`
    }));
  
    setEleveOption(options);
  }, [Eleve]); // Add Eleve as a dependency if it's coming from props or state
  
  const [GroupeAproprie , setGroupeAproprie] = useState([])

  const  handleupGroupe = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Groupebyclasse/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setGroupeAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };
  useEffect(() => {
    // Assuming Eleve is an array
    const options = Eleve.map((element) => ({
      value: element.id,
      label: `${element.nom} ${element.prenom} ${element.matricule}`
    }));
  
    setEleveOption(options);
  }, [Eleve]); 

  const handleChange1= value => {
    setselectedOption1(value)
    setInscriptionInfo({ ...InscriptionInfo, 'eleve_id' : value.value })
  }
  const [actifStep , setactifStep] = useState(0);
  const nextStep = () => {
    setStep(step + 1);
    setactifStep(actifStep + 1)
  };

  const prevStep = () => {
    setStep(step - 1);
    setactifStep(actifStep-1)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInscriptionInfo({ ...InscriptionInfo, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
   
   console.log(InscriptionInfo)
    try {
        const response = await axios.post(host.Backend+'Inscription/', InscriptionInfo);
        console.log(response.data);
        setRDash(false)
        setRDash(true)
        Fermer()

       
    } catch (error) {
        console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
};


  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">
      <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Eleve</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span>
       
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
       <Select value={selectedOption1} isSearchable={true}  onChange={(e) =>{handleChange1(e) }}  options={EleveOption}  />
      </div>
     </div>
   </div>


   </div>
            </form>
        );
      case 2:
        return (
            <form >
            <div class="row mt-3">


    <div class="mb-3 col-md-12">
    <label for="classe" class="form-label">Niveau</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span> 
                  <select className='form-select' 
  onChange={(e) => {
    handleChange(e);
    handleupClasse(e);
  }} name='niveau_id'  value={InscriptionInfo.niveau_id}>
                        <option value={null}>Chosir...</option>   
                          {AllNiveau.map((niveau) => (
                              <option key={niveau.id} value={niveau.id}>
                                {niveau.nom}
                              </option>
                          ))}
                          </select>
     </div>
     </div>
  
   

</div>
            </form>
        );
        case 3:
        return (
            <form >
            <div class="row mt-3">
 

            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Classe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
                          <select className='form-select'  name='classe_id' onChange={(e) => {
    handleChange(e);
    handleupGroupe(e);
  }} value={InscriptionInfo.classe_id} required>
                    <option value={null}>Chosir...</option> 
                      {ClasseAproprie.map((classe) => (
                        <option value={classe.id}>{classe.classe}</option>
                      ))}
                    </select>
     </div>
   </div>



   </div>
            </form>
        );
        case 4:
        return (

            <form >
            <div class="row mt-3">
            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Groupe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span>                          
       <select className='form-select'  name='groupe_id' onChange={(e) => {
    handleChange(e);
  }} value={InscriptionInfo.groupe_id} required>
                    <option value={null}>Chosir...</option> 
                      {GroupeAproprie.map((groupe) => (
                        <option value={groupe.id}>{groupe.groupe}</option>
                      ))}
                    </select>
     </div>
   </div>




   </div>
            </form>

        );

        case 5:
        return (


      <div class="card-body mt-3">
        <div class="mb-3  col-12 mb-0">
          <div class="alert alert-success">
            <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
            <p class="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
          </div>
        </div>

      </div>
            
        );
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Inscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}

      </Modal.Body>
      <Modal.Footer>
      <ProgressMobileStepper
     handleNext={nextStep}
     handleBack={prevStep}
     activeStep={actifStep}
     Steps={4}
     handleSubmit={handleSubmit}

    
    
    
    ></ProgressMobileStepper>
      </Modal.Footer>
    </Modal>
  );
};

export default Inscription;




