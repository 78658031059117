import React from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
const CoursTable = ({Data}) => {

  // Existing data and columns definitions remain the same

  const columns = useMemo(
    () => [
      {
        accessorKey: 'EnseignantNom',
        header: 'Nom',
        size: 150,
      },
      {
        accessorKey: 'EnseignantPrenom',
        header: 'Prenom',
        size: 150,
      },
      {
        accessorKey: 'Matiere',
        header: 'Matiere',
        size: 200,
      },
      {
        accessorKey: 'Classe',
        header: 'Clase',
        size: 150,
      },
      {
        accessorKey: 'groupe',
        header: 'Groupe',
        size: 150,
      },
      {
        accessorKey: 'annee_academique',
        header: 'Annee Academique',
        size: 150,
      }
    ],
    [],
  );

  const handleButtonClick = (rowData) => {
    // Implement your functionality here using rowData from the clicked row
    console.log('Button clicked for row:', rowData);
  };

  const data = Data;

  const table = useMaterialReactTable({
    columns,
    data,
    localization:{MRT_Localization_FR},
  });
  return (

    <MaterialReactTable

      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering

      enableEditing

      enableColumnPinning

      enableRowActions

      enableRowSelection

      enableSelectAll={false}

      initialState={{ showColumnFilters: false, showGlobalFilter: true }}

      localization={MRT_Localization_FR}

    />

  );
};

export default CoursTable;
