import { Button } from 'bootstrap';
import React, { useMemo, useState } from 'react';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import countryList from 'react-select-country-list'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import axios from 'axios';
import host from '../../../../host';


const Enregistrement = ({Ouvrir, Fermer}) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState('')

  const [file, setFile] = useState(null);
  const options = useMemo(() => countryList().getData(), [])

  const [personalInfo, setPersonalInfo] = useState({
    "nom": "",
    "prenom": "",
    "genre": "",
    "date_naissance": "",
    "lieu_naissance": "",
    "poste_administratif": "",
    "civilite": "",
    "matricule": "",
    "telephone1": "",
    "telephone2": "",
    "email": "",
    "adresse": "",
    "type_administrateur": "",
    "photo_profil": ""
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChangePersonal = (e) => {
    if (e.target.name === "photo_profil") {
      const selectedFile = e.target.files[0];
      setFile(URL.createObjectURL(selectedFile));
      setPersonalInfo({ ...personalInfo, [e.target.name]: selectedFile });
  } else {
    setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  }
  };
  const changeHandler = value => {
    setValue(value)
    setPersonalInfo({ ...personalInfo, 'civilite' :  value.label })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in personalInfo) {
        formDataToSend.append(key, personalInfo[key]);
    }

    console.log(personalInfo);

    try {
        // Utilisez Axios pour envoyer les données du formulaire au serveur
        const response = await axios.post( host.Backend, formDataToSend);
        console.log(response.data);


    } catch (error) {
        // Gérez les erreurs ici
        console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
  };

  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <>
            <div class="row mt-3">
      <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-fullname">Nom</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span>
       <input
         type="text"
         class="form-control"
         id="basic-icon-default-fullname"
         name="nom"
         value={personalInfo.nom}
         onChange={handleChangePersonal}
       />
     </div>
   </div>
       <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-fullname">Prenom</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       <input
         type="text"
         class="form-control"
         id="basic-icon-default-fullname"
         name="prenom"
         value={personalInfo.prenom}
         onChange={handleChangePersonal}
       />
     </div>
   </div>

   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Genre</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       <select className='form-select' 
         name="genre"
         value={personalInfo.genre}
         onChange={handleChangePersonal}>
<option  selected value={'M'}>Masculin</option>
<option value={'F'}> Feminin</option>
       </select>
     </div>
   </div>

   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={nextStep} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </>

        
        );
      case 2:
        return (
            < >
            <div class="row mt-3">
            <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-fullname">Date de naissance</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       <input
         type="date"
         class="form-control"
         id="basic-icon-default-fullname"
         placeholder=""
         aria-describedby="basic-icon-default-fullname2"
         onChange={handleChangePersonal}
         value={personalInfo.date_naissance}
         name='date_naissance'
       />
     </div>
   </div>

         <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-fullname">Lieu de naissance</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span>
       <input
         type="text"
         class="form-control"
         id="basic-icon-default-fullname"
         aria-describedby="basic-icon-default-fullname2"
         name='lieu_naissance'
         value={personalInfo.lieu_naissance}
         onChange={handleChangePersonal}
       />
     </div>
   </div>

   <div class="mb-3 col-md-12">
       <label class="form-label" for="country">Civilite</label>
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
<Select  options={options}   value={value} name='civilite' onChange={ (e) => {  changeHandler(e) } } />
</div>
     </div>
   

<div class="mb-3 col-md-12">
       <label class="form-label" for="country">Matricule</label>
       <input
         type="text"
         class="form-control"
         id="basic-icon-default-fullname"
         name='matricule'
         onChange={handleChangePersonal}
         value={personalInfo.matricule}
       />
     </div>
   
     <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={nextStep} class="btn btn-primary me-2 ">Suivant</span>
   </div>
</div>
            </>
        );
        case 3:
        return (
            < >
            <div class="row mt-3">
 
     <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-phone">Telephone</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-phone2" class="input-group-text"
         ><i class="bx bx-phone"></i
       ></span>
       <input
         type="tel"
         id="basic-icon-default-phone"
         class="form-control phone-mask"
         value={personalInfo.telephone1}
         onChange={handleChangePersonal}
         name='telephone1'
       />
     </div>
   </div>
   <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-phone">Telephone</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-phone2" class="input-group-text"
         ><i class="bx bx-phone"></i
       ></span>
       <input
         type="tel"
         id="basic-icon-default-phone"
         class="form-control phone-mask"
         value={personalInfo.telephone2}
         onChange={handleChangePersonal}
         name='telephone2'
       />
     </div>
   </div>
   <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-phone">Adresse</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-phone2" class="input-group-text"
         ><i class="bx bx-phone"></i
       ></span>
       <input
         type="text"
         id="basic-icon-default-phone"
         class="form-control phone-mask"
         value={personalInfo.adresse}
         onChange={handleChangePersonal}
         name='adresse'
       />
     </div>
   </div>
   <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-phone">Email</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-phone2" class="input-group-text"
         ><i class="bx bx-phone"></i
       ></span>
       <input
         type="text"
         id="basic-icon-default-phone"
         class="form-control phone-mask"
         value={personalInfo.email}
         onChange={handleChangePersonal}
         name='email'
       />
     </div>
   </div>

   <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={nextStep} class="btn btn-primary me-2 ">Suivant</span>
   </div>
   </div>
            </>
        );
        case 4:
        return (

            <>
            <div class="row mt-3">


   <div class="mb-3 col-md-6">
       <label class="form-label" for="country">Poste Administratif</label>
       <input
         type="text"
         id="basic-icon-default-phone"
         class="form-control phone-mask"
         value={personalInfo.poste_administratif}
         onChange={handleChangePersonal}
         name='poste_administratif'

       />
     </div>

            <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-phone">Privilege</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-phone2" class="input-group-text"
         ><i class="bx bx-phone"></i
       ></span>
       <select className='form-select' 
         name="type_administrateur"
         value={personalInfo.type_administrateur}
         onChange={handleChangePersonal}>
<option  selected value={'ALLACCESS'}>All Access &#9733;&#9733;&#9733;&#9733; </option>
<option value={'ACCESSRESTREINT'}>Access Restreint &#9733;&#9733;</option>
       </select>
     </div>
   </div>




   <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={nextStep} class="btn btn-primary me-2 ">Suivant</span>
   </div>
   </div>
            </>

        );
        case 5:
        return (
            <>
            <div class="row mt-3">
           
<div class="card-body">
<div class="d-flex align-items-start align-items-sm-center gap-4">

<img src={ file || "/assets/img/avatars/2.png"} alt="user-avatar" class="d-block rounded" height="100" width="100" id="uploadedAvatar" />

<div class="button-wrapper">
<label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
<span class="d-none d-sm-block">Choisir une photo</span>
 <i class="bx bx-upload d-block d-sm-none"></i>
 <input type="file" hidden id="upload" name='photo_profil' class="account-file-input" accept="image/png, image/jpeg" onChange={handleChangePersonal} />
</label>
<button type="button" class="btn btn-outline-secondary account-image-reset mb-4">
 <i class="bi bi-photo d-block d-sm-none"></i>
 <span class="d-none d-sm-block">Photo Live</span>
</button>

<p class="text-muted mb-0">Seul JPG ou PNG sont Accepte. Taille Max 800KO</p>
</div>
</div>
</div>

<div class="mb-3 mt-3 col-md-12">
       
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        maxFiles={3}
        server="/api"
        name="files" /* sets the file input name, it's filepond by default */
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
   </div>



   <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <button  onClick={handleSubmit} class="btn btn-primary me-2 ">Envoyer</button>
   </div>
   </div>
            </>

        );
        case 6:
        return (


      <div class="card-body mt-3">
        <div class="mb-3  col-12 mb-0">
          <div class="alert alert-success">
            <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
            <p class="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
          </div>
        </div>

      </div>
            
        );
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Enregistrement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default Enregistrement;




