import AnneeAcademique from './Reglages/AnneeScolaire';
import Semestre from './Reglages/Semestre';
import Groupe from './Reglages/Groupe';
import Classe from './Reglages/Classe';
import Matiere from './Reglages/Matiere';
import Niveau from './Reglages/Niveau';
import Table_AnneeScolaire from './Reglages/Table_AnneeScolaire';
import Table_Semestre from './Reglages/Table_Semestre';
import Table_Matiere from './Reglages/Table_Matiere';
import Table_Groupe from './Reglages/Table_groupe';
import Table_Niveau from './Reglages/Table_niveau';
import Table_Classe from './Reglages/Table_classe';
import ProfileEcole from './Reglages/ProfileEcole';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import host from '../../../host';
const authToken = localStorage.getItem('authToken');



export default function Reglage() {
const [Etape , setEtape] = useState(0);

const [T_data , setT_data] = useState(0);
const [AllClasse , setAllClasse] = useState([])
const [AllNiveau , setAllNiveau] = useState([])
useEffect(() => {
  const fetchDataa = async () => {
    try {

      const response = await axios.get(host.Backend+'AllClasse/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
      });

      setAllClasse(response.data);
    } catch (error) {
      console.error('Erreur lors de la requête API:', error);
    } 
  };

  fetchDataa();
}, []);
useEffect(() => {
  
  const fetchDataa = async () => {
    try {

      const response = await axios.get(host.Backend+'AllNiveau/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
      });

      setAllNiveau(response.data);
    } catch (error) {
      console.error('Erreur lors de la requête API:', error);
    } 
  };

  fetchDataa();
}, []);
const [_ , setisAnneeacademique] = useState(false);
const anneeacademique = () => {
  setEtape(1)
  setisAnneeacademique(true);
}
const close_anneeacademique = () => {
  setEtape(0)
  setisAnneeacademique(false);
}

const [isSemestre , setisSemestre] = useState(false)
const semestre = () => {
  setEtape(2)
  setisSemestre(true);
}
const close_semestre = () => {
  setEtape(0)
  setisSemestre(false);
}

const [isGroupe , setisGroupe] = useState(false)
const groupe = () => {
  setEtape(3)
  setisClasse(true);
}
const close_groupe = () => {
  setEtape(0)
  setisClasse(false);
}


const [isClasse , setisClasse] = useState(false)
const classe = () => {
  setEtape(4)
  setisClasse(true);
}
const close_classe = () => {
  setEtape(0)
  setisClasse(false);
}


const [isNiveau , setisNiveau] = useState(false)
const niveau = () => {
  setEtape(5)
  setisNiveau(true);
}
const close_niveau = () => {
  setEtape(0)
  setisNiveau(false);
}

const [isMatiere , setisMatiere] = useState(false)
const matiere = () => {
  setEtape(6)
  setisMatiere(true);
}
const close_matiere= () => {
  setEtape(0)
  setisMatiere(false);
}

const [isT_anneeacademique , setisT_anneeacademique] = useState(false)
const t_anneeacademique = () => {
  setisT_anneeacademique(true);
  setT_data(1);
}

const [isT_semestre , setisT_semestre] = useState(false)
const t_semestre = () => {
  setisT_semestre(true);
  setT_data(2);
}
const [isT_Matiere , setisT_Matiere] = useState(false)
const t_matiere = () => {
  setisT_Matiere(true);
  setT_data(3);
}

const [isT_niveau , setisT_niveau] = useState(false)
const t_niveau  = () => {
  setT_data(4);
}

const [isT_classe , setisT_classe] = useState(false)
const t_classe = () => {
  setT_data(5);
}

const [isT_groupe , setisT_groupe] = useState(false)
const t_groupe = () => {
  setT_data(6);
}
const [isT_profileEcole , setisT_profileEcole ] = useState(false)
const profileEcole = () => {
  setT_data(7);
}

const Etapes = () => {
    switch (Etape) {
      case 0:
        return (
          <div></div>
        );
      case 1:
        return (
          <AnneeAcademique
          Fermer={close_anneeacademique}
          Ouvrir = {anneeacademique}
          P_AllNiveau = {AllNiveau}
          
          />
        ) ;
        case 2:
          return (
            <Semestre

          Fermer={close_semestre}
          Ouvrir = {semestre}
          P_AllNiveau = {AllNiveau}
            />
          ) ;
          case 3:
            return (
              <Groupe
  
            Fermer={close_groupe}
            Ouvrir = {groupe}
            P_AllNiveau = {AllNiveau}
              />
            ) ;
            case 4:
              return (
                <Classe
    
              Fermer={close_classe}
              Ouvrir = {classe}
              P_AllNiveau = {AllNiveau}
                />
              ) ;
              case 5:
                return (
                  <Niveau
      
                Fermer={close_niveau}
                Ouvrir = {niveau}
                  />
                ) ;
              case 6:
                return (
                  <Matiere
      
                Fermer={close_matiere}
                Ouvrir = {matiere}
                P_AllNiveau = {AllNiveau}
                  />
                ) ;
    }
  };


  const TDATA = () => {
    switch (T_data) {
      case 0:
        return (
          <div></div>
        );
      case 1:
        return (
          <Table_AnneeScolaire
            P_anneeacademique={anneeacademique}
          />
        ) ;
        case 2:
          return (
            <Table_Semestre
              P_Semestre={semestre}
            />
          ) ;
          case 3:
            return (
              <Table_Matiere
              P_Matiere={matiere}
              />
            ) ;
            case 4:
              return (
                <Table_Niveau
                P_Niveau={niveau}
                
                />
              ) ;
              case 5:
                return (
                  <Table_Classe
                  P_Classe={classe}
                  
                  />
                ) ;
                case 6:
                  return (
                    <Table_Groupe
                    P_Groupe={groupe}
                    
                    />
                  ) ;

                case 7:
                  return (
                    <ProfileEcole
                    
                    />
                  ) ;

    }
  };


  return (
    <div> 
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Reglage</h4>

<p>
Centralisez la gestion des élèves : profils, notes, absences, dossiers individuels, communication parentale... Tout ce dont vous avez besoin, à portée de clic.
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={profileEcole}  class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-redbubble mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Profil de Ecole</p>
      </div>
    </div>
    <div onClick={t_groupe}  class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-adobe mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Groupe</p>
      </div>
    </div>
    <div onClick={t_classe}  class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-adobe mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Classes</p>
      </div>
    </div>
    <div onClick={t_niveau}  class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-algolia mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Niveaux</p>
      </div>
    </div>
    <div onClick={t_matiere}  class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-algolia mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Matières</p>
      </div>
    </div>
    <div onClick={t_semestre} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bx bxl-audible mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Semestre</p>
      </div>
    </div>
    <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div onClick={t_anneeacademique} class="card-body">
        <i class="bx bxl-figma mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Annee Scolaire</p>
      </div>
    </div>
    
  </div>
<Etapes ></Etapes>
<TDATA></TDATA>
</div>
  )
}
