import React from 'react';

const Footer = () => {


  return (
    <footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
      <div class="mb-2 mb-md-0">
        ©
        <script>
          document.write(new Date().getFullYear());
        </script>
         made by &nbsp;
        <a href="https://www.go2ai.tech" target="_blank" class="footer-link fw-bolder"> GO2AI</a>
      </div>
      <div>
        <a href="https://www.savane.go2ai.tech/license/" class="footer-link me-4" target="_blank">License</a>

        <a
          href="https://www.savane.go2ai.com/doc"
          target="_blank"
          class="footer-link me-4"
          >Documentation</a
        >

        <a
          href="https://github.com/savane"
          target="_blank"
          class="footer-link me-4"
          >Support</a
        >
      </div>
    </div>
  </footer>
   
    );
}

export default Footer;