import * as React from 'react';
import {useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function ProgressMobileStepper({ handleNext, handleBack, activeStep, Steps , handleSubmit}) {
  const theme = useTheme();
  const lastStep = Steps - 1;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const renderNextButton = () => {
    if (activeStep === lastStep) {
      // Si c'est le dernier step, afficher le bouton "Envoyer"
      return (
        <Button type='submit' size="small" onClick={handleSubmit} >
          Envoyer
        </Button>
      );
    } else {
      // Sinon, afficher le bouton "Next"
      return (
        <Button color='primary' size="small" onClick={handleNext} disabled={activeStep === lastStep}>
          
          {isSmallScreen ? null : 'Suivant'}
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      );
    }
  };

  return (
    <MobileStepper
      variant="progress"
      steps={Steps}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 500, flexGrow: 1 }}
      nextButton={renderNextButton()}
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          {isSmallScreen ? null : 'Retour'}
          
        </Button>
      }
    />
  );
}
