import { Button } from 'bootstrap';
import React, { useState } from 'react';

import { FilePond, registerPlugin } from 'react-filepond'

import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';


const EnregistrementEmmergement = ({Ouvrir, Fermer}) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({ firstName: '', lastName: '' , Gender: ''});
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChangePersonal = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Vous pouvez faire quelque chose avec les données du formulaire ici
    console.log('Informations personnelles:', personalInfo);
    console.log('Informations de connexion:', loginInfo);
    setStep(step + 1);
  };

  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">

       <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Enseignant</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       <input
         type="text"
         class="form-control"
         id="basic-icon-default-fullname"
         name="lastName"
         value={personalInfo.lastName}
         onChange={handleChangePersonal}
       />
     </div>
   </div>


   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={nextStep} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </form>

        
        );
        case 2:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">
            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Cours</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
<select className='form-select' 
         name="Gender"
         value={personalInfo.Gender}
         onChange={handleChangePersonal}>
<option>Abdoul Aziz Histoire 6eme</option>
       </select>
     </div>
   </div>


   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={nextStep} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </form>

        
        );
      case 3:
        return (
            <form >
            <div class="row mt-3">
            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Titre du cours</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <input
         type="text"
         class="form-control"
         id="basic-icon-default-fullname"
         name="firstName"
         value={personalInfo.firstName}
         onChange={handleChangePersonal}
       />

     </div>
   </div>



   
     <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={nextStep} class="btn btn-primary me-2 ">Envoyer</span>
   </div>
</div>
            </form>
        );

        case 4:
        return (


      <div class="card-body mt-3">
        <div class="mb-3  col-12 mb-0">
          <div class="alert alert-success">
            <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
            <p class="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
          </div>
        </div>

      </div>
            
        );
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Emmergement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default EnregistrementEmmergement;




