import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { Avatar, CircularProgress } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import {  useEffect, useState } from 'react'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import Select from 'react-select'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import moment from 'moment';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
export const EleveProfil = ({id , Eleve}) => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleButtonClick = (newValue) => {
        setValue(newValue);
      };
      const [EleveData, setEleveData] = useState([]);
      const [NotesData, setNotesData] = useState([]);
      const [InscriptionData, setInscriptionData] = useState([]);
      const [SituationData, setSituationData] = useState([]);
      const [niveau, setniveau] = useState([]);
      const [Classe, setClasse] = useState([]);
      const [groupe, setgroupe] = useState([]);
      const [annee_academique, setannee_academique] = useState([]);
      const [payementData, setPayement] = useState([]);
      useEffect(() => {
      
        const fetchDataa = async () => {
          try {
      
            const response = await axios.get(host.Backend+`EleveProfil/${id}/`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
              },
            });
      
            setEleveData(response.data.Eleve);
            setNotesData(response.data.Notes);
            setInscriptionData(response.data.Inscription);
            setSituationData(response.data.situation_matiere);
            setniveau(response.data.niveau)
            setClasse(response.data.Classe)
            setgroupe(response.data.groupe)
            setannee_academique(response.data.annee_academique)
            setPayement(response.data.Payement)
          } catch (error) {
            console.error('Erreur lors de la requête API:', error);
          } 
        };
      
        fetchDataa();
      }, []);
  return (
    

<div>
<h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light" onClick={Eleve}  >Gestion des eleves /</span> Profil</h4>

<p>
Centralisez la gestion des élèves : profils, Preincription, Inscription... Tout ce dont vous avez besoin, à portée de clic.
</p>
    <div class="row pb-0">
        <div class="col-xl-8">
            <div className="card">
              <div className="card-body pb-0">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-3">
                    <div className="text-center ">
   <div className="w-100 d-flex justify-content-center">
                                          <Avatar
        alt="Remy Sharp"
        src={host.Backend+`${EleveData.photo_profil}`}
        sx={{ width: 150, height: 150 }}
      />
      </div>
                      <h6 className="text-primary font-size-12 mt-3 mb-2">{EleveData.nom ? EleveData.nom : ''}  &nbsp;{EleveData.nom ? EleveData.prenom : ''}</h6>
                      <p className="text-muted mb-0">{Classe.classe ? Classe.classe : ''} {groupe.groupe ? groupe.groupe : ''} / {annee_academique.annee_academique ? annee_academique.annee_academique : ''}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-9">
                      <div>
                        <h4 className="card-title mb-2">Profil Élève</h4>

      <ListGroup>
     <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Matricule</div>
          {EleveData.nom ? EleveData.matricule : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Telephone</div>
          {EleveData.nom ? EleveData.telephone1 : ''} / {EleveData.nom ? EleveData.telephone2 : ''}
        </div>
      </ListGroup.Item>

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Adresse</div>
          {EleveData.nom ? EleveData.adresse : ''}
        </div>
      </ListGroup.Item>

      </ListGroup>
                      </div>
                      <ul className="nav nav-tabs nav-tabs-custom border-bottom-0 mt-3 nav-justfied" role="tablist">
                      <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                            m: 1,
                            },
                        }}
                        >
                        <ButtonGroup variant="text" aria-label="Basic button group">
                            <Button  onClick={() => handleButtonClick('1')}>Payement</Button>
                            <Button  onClick={() => handleButtonClick('2')}>Resultat</Button>
                            <Button  onClick={() => handleButtonClick('3')}>Discipline</Button>
                        </ButtonGroup>
                        </Box>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>


        <div className="card">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <TabPanel value="1">
                    <h4 className="card-title mb-4">Payement</h4>
                    {payementData.map((inscription) => (
        <div key={Object.keys(inscription)[0]}>
          <h3>{Object.keys(inscription)[0]}</h3>
          <Table responsive="sm" striped bordered>
            <thead>
              <tr>
                <th>AC</th>
                <th>Montant</th>
                <th>Reste</th>
                <th>Responsable</th>
                <th>DT</th>
              </tr>
            </thead>
            <tbody>
              {inscription[Object.keys(inscription)[0]].map((versement) => (
                <tr key={versement.create_at}>
                  <td>{versement.annee_academique}</td>
                  <td>{versement.montant}</td>
                  <td>{versement.reste}</td>
                  <td>{versement.responsable}</td>
                  <td>{moment(versement.create_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
          
           </TabPanel>
        <TabPanel value="2">
                    <h4 className="card-title mb-4">Resultat</h4>

          <h3>Situation par matiere</h3>
                    <Table responsive="sm" striped bordered>
        <thead>
          <tr>
            <th>Matiere</th>
            <th>MC</th>
            <th>COMP</th>
            <th>MG</th>
            <th>Coef</th>
            <th>MC Coef</th>
            <th>MG Coef</th>
            <th>RANG</th>
            <th>S</th>
            <th>AC</th>
          </tr>
        </thead>
        <tbody>
          {SituationData.map((matiere) => (
            <tr key={matiere.Matiere}>
            <td>{matiere.Matiere}</td>
            <td>{matiere.NotesdeClasse}</td>
              <td>{matiere.NotesdeComposition}</td>
              <td>{matiere.MoyenneGenerale}</td>
              <td>{matiere.Coeficient}</td>
              <td>{matiere.NotesdeClasseSur}</td>
              <td>{matiere.MoyenneGeneraleSur}</td>
              <td>{matiere.Rang}</td>
              <td>{matiere.Semestre}</td>
              <td>{matiere.AnneeAcademique}</td>
            </tr>
          ))}
        </tbody>
      </Table><br />
          <h3>Notes</h3>
                    <Table responsive="sm" striped bordered>
        <thead>
          <tr>
            <th>Matiere</th>
            <th>Note</th>
            <th>Rang</th>
            <th>Date</th>
            <th>S</th>
            <th>AC</th>
          </tr>
        </thead>
        <tbody>
          {NotesData.map((notes) => (
            <tr key={notes.Matiere}>
            <td>{notes.Matiere}</td>
            <td>{notes.Notes ? `${notes.Notes} / ${notes.sur}` : 'N/A'}</td>
            <td>{notes.Rang}</td>
            <td>{notes.date}</td>
            <td>{notes.Semestre}</td>
            <td>{notes.AnneeAcademique}</td>
            </tr>
          ))}
        </tbody>
      </Table>

        </TabPanel>
        <TabPanel value="3">
                    <h4 className="card-title mb-4">Discipline</h4>




        </TabPanel>
      </TabContext>
    </Box>

                </div>
                </div>

 
                <div className="col-xl-4 col-md-6" id="team-4">
  <div className="card">
    <div className="card-body">

        <h5 className="mb-1 font-size-17">Informations sur l'Élève</h5>
        <p className="text-muted font-size-13 mb-0"></p>
        <ListGroup as="ol" >

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Genre</div>
          {EleveData.nom ? EleveData.genre : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Date de naissance</div>
          {EleveData.nom ? EleveData.date_naissance : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Lieu de naissance</div>
          {EleveData.nom ? EleveData.lieu_naissance : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Civilite</div>
          {EleveData.nom ? EleveData.civilite : ''}
        </div>
      </ListGroup.Item>
  
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Responsable</div>
          
          {EleveData.nom ? EleveData.qualite_responsable : ''} : {EleveData.nom ? EleveData.responsable : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Telephone</div>
          {EleveData.nom ? EleveData.phone_responsable : ''} 
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Adresse Responsable</div>
          {EleveData.nom ? EleveData.adresse_responsable : ''} 
        </div>
      </ListGroup.Item>

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Autre Info</div>
          
        </div>
      </ListGroup.Item>

      </ListGroup>
    </div>
    </div>
  </div>



</div>
</div>
  )
}
