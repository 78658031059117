import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Card from 'react-bootstrap/Card';

const Loader = ( ) => {
    const [tour, setTour] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        // Change color after each tour
        setTour((prevTour) => (prevTour + 1) % 2);
      }, 1000); // Change the interval as needed
  
      // Clear the interval on component unmount
      return () => clearInterval(interval);
    }, []);
  
    // Choisissez les couleurs en fonction du tour
    const color = tour === 0 ? '#e64a19' : '#536dfe';
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      }}
    >
      <Card style={{ width: '5rem' }}>
        <Card.Body>
          <CircularProgress disableShrink style={{ color }} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Loader;

