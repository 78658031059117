import React, { useState , useEffect } from 'react'
import EnregistrementAdm from './Administrateurs/EnregistrementAdm';
import AdministrateursTable from './Administrateurs/AdministrateursTable';
import EnregistrementCours from './Cours/EnregistrementCours';
import CoursTable from './Cours/CoursTable';
import axios from 'axios';
import host from '../../../host';
const authToken = localStorage.getItem('authToken');

export default function Cours() {

  const [isEnregistrement , setisEnregistrement]= useState(false);
  const enregistrement= ()=>{setisEnregistrement(true)}
  const close_enregistrement= ()=>{setisEnregistrement(false)}
  const [CoursData , setCoursData] = useState([])
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'MatiereAttribution/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setCoursData(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);
  return (
    <div> 
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Cours</h4>

<p>
Optimisez la gestion enseignante : planning des cours, évaluations, communication avec les élèves, suivi individuel, ressources pédagogiques... Simplifiez chaque aspect de l'enseignement.
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={enregistrement} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person-plus"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Enregistrer un Cours</p>
      </div>
    </div>
 
{isEnregistrement && 
<EnregistrementCours
Ouvrir={enregistrement}
Fermer = {close_enregistrement}
></EnregistrementCours>}
    
<CoursTable Data={CoursData} ></CoursTable>
  </div>
</div>

  )
}
