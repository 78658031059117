import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
export default function Groupe({Ouvrir, Fermer, P_AllNiveau}) {

  const [formData, setFormData] = useState({

    classe_id: '',
    groupe: ''

  });
  const [alert, setAlert] = useState([]);
  const [ClasseAproprie , setClasseAproprie] = useState([])
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const  handleupClasse = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Classebyniveau/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setClasseAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Utilisez Axios pour envoyer les données du formulaire au serveur
      const response = await axios.post(host.Backend+'Groupe/', [formData]);
      
      switch (response.status) {
        case 201:
          setAlert({ type: 'success', message: 'Niveau créé avec succès!' });
          break;
        case 400:
          setAlert({ type: 'danger', message: 'Format de texte invalide ou le niveau existe déjà' });
          break;
        default:
          setAlert({ type: 'danger', message: 'Erreur inattendue lors de la création du niveau' });
          break;
      }
    } catch (error) {
      // Gérez les erreurs ici
      console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
  };


  return (
    <Modal
    show={Ouvrir}
    onHide={Fermer}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Création de Groupe</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
    <Modal.Body>
    <div class="modal-body">
                <div class="row">
                <div class="col mb-3">
          <label for="classe" class="form-label">Niveau</label>
                  <select className='form-select'  value={formData.nom}
  onChange={(e) => {
    handleChange(e);
    handleupClasse(e);
  }} name='niveau_id'>
                        <option value={null}>Chosir...</option>   
                          {P_AllNiveau.map((niveau) => (
                              <option key={niveau.id} value={niveau.id}>
                                {niveau.nom}
                              </option>
                          ))}
                          </select>
                  </div>
                  </div>
                  <div class="row">
                  <div class="col mb-3">
                    <label for="classe" class="form-label">Classe</label>
                    <select className='form-select'  name='classe_id' onChange={(e) => {
    handleChange(e);
  }} required>
                    <option value={null}>Chosir...</option> 
                      {ClasseAproprie.map((classe) => (
                        <option value={classe.id}>{classe.classe}</option>
                      ))}
                    </select>
                  </div>
                  
                  </div>
                  <div class="row">
                  <div class="col mb-3">
                    <label for="classe" class="form-label">Groupe</label>
                    <input type='text' name='groupe' className='form-control'  onChange={(e) => {handleChange(e)}}  placeholder='Groupe...' />
                  </div>
                  </div>
                  
               
              </div>
                  
    </Modal.Body>
    <Modal.Footer>
    <button  onClick={Fermer} type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                  Fermer
                </button>
                <button type="submit" class="btn btn-primary">Ajouter</button>
    </Modal.Footer>
    </form>
  
  </Modal>
  )
}
