import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

const TableAllnote = ({noteInfo , addNote , Evaview , addcompo}) => {
  const [editedUsers, setEditedUsers] = useState({});
  const [isUpdatingUsers, setIsUpdatingUsers] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dataNote, setdataNote] = useState([])
  const counters = { Devoir: 0, Interogation: 0 };
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [Editingvalue , setEditingvalue] = useState(null)
  const [DataModal , setDataModal] = useState({})
  const [DataModal2 , setDataModal2] = useState({})
  const [modeEdit , setmodeEdit] = useState(false)
  const [Refresh ,setRefresh] = useState(false)
  const Editmode = () =>{
    setmodeEdit(!modeEdit)
  }
  const getCounter = (type) => {
  counters[type]++;
  return counters[type];
};
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+`Notestable/${noteInfo.niveau}/${noteInfo.classe}/${noteInfo.groupe}/${noteInfo.annee_academique}/${noteInfo.semestre}/${noteInfo.matiere}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setdataNote(response.data);
        console.log(dataNote)
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, [Refresh]);



  const handleSaveUser = (rowData) => {
    setEditedUsers((prevEditedUsers) => ({
      ...prevEditedUsers,
      [rowData.id]: rowData,
    }));
  };


  

  const handleButtonClick = (rowData) => {
    // Implémentez votre fonctionnalité ici en utilisant rowData de la ligne cliquée
    console.log('Button clicked for row:', rowData);
  };

  const handleSaveUsers = async () => {
    try {
      setIsUpdatingUsers(true);

      // Remplacez la ligne suivante par votre véritable point d'API pour sauvegarder les données dans la base de données
      console.log(editedUsers)
    } catch (error) {
      // Gérer les erreurs réseau ou autres exceptions
    } finally {
      setIsUpdatingUsers(false);
    }
  };

  const evaluationData = dataNote?.[0]?.Evaluation || [];

  const noteData = dataNote?.[0]?.Note || [];



  const [editingCell, setEditingCell] = useState(null);

  const handleSingleClick = (nom , prenom , id , eva_type , note ,  eva , note_id ) => {
    setDataModal2({'nom' : nom , 'prenom' : prenom , 'id' : id , 'eva_type' : eva_type , 'note' : note , 'note_id' : note_id  , 'eva' : eva  })
    setModalOpen2(true);
  };

  const handleDoubleClick = (row, evaluationId , value) => {
    setEditingvalue(value)
    setEditingCell({ row, evaluationId });
    console.log('bonjour')
  };
  const handleInputChange = (event) => {
    setEditingvalue(event.target.value);
  };

  const handleInputBlur = (nom , prenom , eleve_id , eva_type , note , noteedited , eva , note_id) => {
    setDataModal({'nom' : nom , 'prenom' : prenom , 'eleve_id' : eleve_id , 'eva_type' : eva_type , 'note' : note , 'noteedited' : noteedited  , 'note_id' : note_id  , 'eva' : eva  })
    setModalOpen(true);
  };

  const handleModalConfirm = async () => {
   const data = {
      'note' : DataModal.note ,
    }
    try {
      const response = await axios.put(host.Backend+`Notes/${DataModal.eva}/${DataModal.note_id}/`, data);
      setRefresh(!Refresh)

  } catch (error) {
      // Gérez les erreurs ici
      console.error('Erreur lors de l\'envoi du formulaire :', error);
  }
    setEditingCell(null);
    setModalOpen(false);
  };
  const handleModalConfirm2 = async () => {

    try {
      const response = await axios.put(host.Backend+`ActiveNotes/${DataModal2.eva}/${DataModal2.note_id}/`, );
      setRefresh(!Refresh)

  } catch (error) {
      // Gérez les erreurs ici
      console.error('Erreur lors de l\'envoi du formulaire :', error);
  }

    setModalOpen2(false);
  };
  const handleModalCancel = () => {
    setModalOpen2(false);
  };

  const getCellColor = (isActive, evaluationId) => {

    return isActive ? 'green' : 'red';
  };


  return (
    <Box>
    <Box sx={{ display: 'flex', gap: '1rem', p: '2px' }}>
      <Button variant="primary" onClick={addNote}>
      Ajouter
      </Button>
      <Button variant="primary" onClick={Editmode}>
          EditMode
      </Button>
      <Button  variant="primary" onClick={Evaview}>
      Evaluation
      </Button>
      <Button  variant="primary" onClick={addcompo}>
           COMP
      </Button>
    </Box>
    <TableContainer>
    <Table sx={{ minWidth: 500 + (evaluationData.length * 100) }}>
        <TableHead>
          <TableRow>
            <TableCell className="left-align">Nom</TableCell>
            <TableCell>Prénom</TableCell>
            {evaluationData.map((evaluation) => (
              <TableCell key={evaluation.id}>
                {evaluation.type === 'Devoir' ? 'D' : 'I'}{getCounter(evaluation.type)} sur {evaluation.sur}
              </TableCell>
            ))}
            <TableCell>MC</TableCell>
            <TableCell>COMP</TableCell>
            <TableCell>MG</TableCell>
            <TableCell>Rang</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {noteData.map((row) => (
            <TableRow key={row.eleve_nom + row.eleve_prenom}>
              <TableCell>{row.eleve_nom}</TableCell>
              <TableCell>{row.eleve_prenom}</TableCell>
              {evaluationData.map((evaluation) => (
                  <>
                  {
  modeEdit ? (
                  <TableCell  key={evaluation.id}
                  
                  onClick={() => handleDoubleClick(row, evaluation.id, row[evaluation.id][0])}

                  style={{ cursor: 'pointer', color: getCellColor(row[evaluation.id][2], evaluation.id) }}
                >
                  {editingCell && editingCell.row === row && editingCell.evaluationId === evaluation.id ? (
                    <input
                      type="number"
                      value={Editingvalue}
                      onChange={(event) => handleInputChange(event)}
                      onBlur={() => handleInputBlur(row.eleve_nom, row.eleve_prenom, row.eleve_id, evaluation.type, Editingvalue, row[evaluation.id][0], evaluation.id, row[evaluation.id][1])}
                      style={{
                        border: 'none',
                        padding: 0,
                        backgroundColor: 'transparent',
                        width: '50px',
                        height: '100%',
                        outline: 'none',
                      }}
                    />
                  ) : (
                    row[evaluation.id][0]
                  )}
                </TableCell>
                ) : (
                  <TableCell
                    key={evaluation.id}
                    
                  onDoubleClick={() => handleSingleClick(row.eleve_nom, row.eleve_prenom, row.eleve_id, evaluation.type, row[evaluation.id][0] , evaluation.id, row[evaluation.id][1])}
                    style={{ cursor: 'pointer', color: getCellColor(row[evaluation.id][2], evaluation.id) }}
                  >
                    {row[evaluation.id][0]}
                  </TableCell>
                    )
                  }
                  </>
              ))}
              <TableCell>{row.notes_classe}</TableCell>
              <TableCell>{row.notes_composition}</TableCell>
              <TableCell>{row.moyenne_generale}</TableCell>
              <TableCell>{row.rang}</TableCell>
            </TableRow>
          ))}





        </TableBody>
        
      </Table>
      <Modal
      show={isModalOpen} onHide={() => setModalOpen(false)}
        contentLabel="Confirm Changes"
      >
         <Modal.Header closeButton>
          <Modal.Title>Confirmer le changement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <p>
  Confirmez-vous la modification de la note de <strong>{DataModal.nom} {DataModal.prenom}</strong> pour l'évaluation de type <strong>{DataModal.eva_type}</strong> ?
  <br />
  Ancienne note : <em>{DataModal.noteedited}</em><br></br> Nouvelle note : <em>{DataModal.note}</em>
</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalConfirm}>
            Oui
          </Button>
          <Button variant="primary" onClick={handleModalCancel}>
            Non
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
      show={isModalOpen2} onHide={() => setModalOpen2(false)}
        contentLabel="Confirm Changes"
      >
         <Modal.Header closeButton>
          <Modal.Title>Confirmer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <p>
  Êtes-vous sûr(e) de ne pas prendre en compte la note de <strong>{DataModal2.nom} {DataModal2.prenom}</strong> pour l'évaluation de type <strong>{DataModal2.eva_type}</strong> dans le calcul ?
  <br />
   La note est : <em>{DataModal2.note}</em>
</p>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalConfirm2}>
            Oui
          </Button>
          <Button variant="primary" onClick={() => setModalOpen2(false)}>
            Non
          </Button>
        </Modal.Footer>
      </Modal>
    </TableContainer>
    <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
     

      {Object.values(validationErrors).some((error) => !!error) && (
        <Typography color="error">Fix errors before submitting</Typography>
      )}
    </Box>
  </Box>
);
};


export default TableAllnote;
const validateRequired = (value) => !!value.length;