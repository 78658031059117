import { Button } from 'bootstrap';
import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import { useTheme } from '@mui/material/styles';
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import ProgressMobileStepper from '../step';

import axios from 'axios';
import host from '../../../../host';

const Preincription = ({Ouvrir, Fermer, setRDash}) => {
    const theme = useTheme();
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [alertt, setAlertt] = useState(null);


  const [personalInfo, setPersonalInfo] = useState({

    "nom": "",
    "prenom": "",
    "genre": "",
    "date_naissance": "",
    "lieu_naissance": "",
    "civilite": "",
    "matricule": "",
    "telephone1": "",
    "telephone2": "",
    "adresse": "",
    "responsable": "",
    "qualite_responsable": "",
    "adresse_responsable": "",
    "phone_responsable": "",
    "is_malade": "",
    "malade": "",
    "photo_profil": "",
  });


  const [actifStep , setactifStep] = useState(0);
  const [value, setValue] = useState('')
  const [selectedOption1, setselectedOption1]= useState('')
  const [maladi, setmaladi]= useState('')
  const [Ismalade, setIsmalade]= useState(false)
  const [groupeSang, setgroupeSang]= useState('')

  const [QualiteResponsable, setQaliteResponsable]= useState('')
  const options = useMemo(() => countryList().getData(), [])
  const genderOption=[
    { value: 'M', label: 'Masculin' },
    { value: 'F', label: 'Féminin' },
  ]
 const handleChange1 = value => {
  setselectedOption1(value)
  setPersonalInfo({ ...personalInfo, 'genre' : value.value })
}
const handleChange2 = value => {
  setmaladi(value)
  if (value.value == 'Oui'){
    setIsmalade(true)
  }else{
    setIsmalade(false)
  }
  setPersonalInfo({ ...personalInfo, 'is_malade' :  value.value })
}

const handleChange3 = value => {
  setgroupeSang(value)
  setPersonalInfo({ ...personalInfo, 'groupe_sanguin' :  value.value })
}

const handleChange4 = value => {
  setQaliteResponsable(value)
  setPersonalInfo({ ...personalInfo, 'qualite_responsable' : value.value })
}
  const changeHandler = value => {
    setValue(value)
    setPersonalInfo({ ...personalInfo, 'civilite' :  value.label })
  }
  
  const nextStep = () => {
    setStep(step + 1);
    setactifStep(actifStep + 1);

  };

  const prevStep = () => {
    setStep(step - 1);
    setactifStep(actifStep - 1);
  };






  const handleChange = (e) => {
    if (e.target.name === "photo_profil") {
      const selectedFile = e.target.files[0];
      setFile(URL.createObjectURL(selectedFile));
      setPersonalInfo({ ...personalInfo, [e.target.name]: selectedFile });
  } else {
    setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  }


  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in personalInfo) {
        formDataToSend.append(key, personalInfo[key]);
    }

    console.log(personalInfo);

    try {
        // Utilisez Axios pour envoyer les données du formulaire au serveur
        const response = await axios.post(host.Backend+'Eleve/', formDataToSend);
        console.log(response.data);
        setRDash(false)
        setRDash(true)
        Fermer()


    } catch (error) {
        // Gérez les erreurs ici
        console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
};


  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
          <>
  <div className="row mt-3">
    <div className="mb-3 col-md-6">
      <label className="form-label" htmlFor="basic-icon-default-fullname1">Nom</label>
      <div className="input-group input-group-merge">
        <span id="basic-icon-default-fullname1" className="input-group-text">
          <i className="bx bx-user"></i>
        </span>
        <input
          type="text"
          className="form-control"
          id="basic-icon-default-fullname1"
          name="nom"
          value={personalInfo.nom}
          onChange={handleChange}
          pattern="[A-Za-z]{32}" 
        />
      </div>
    </div>
    <div className="mb-3 col-md-6">
      <label className="form-label" htmlFor="basic-icon-default-fullname2">Prénom</label>
      <div className="input-group input-group-merge">
        <span id="basic-icon-default-fullname2" className="input-group-text">
          <i className="bx bx-user"></i>
        </span>
        <input
          type="text"
          className="form-control"
          id="basic-icon-default-fullname2"
          value={personalInfo.prenom}
          name="prenom"
          onChange={handleChange}
        />
      </div>
    </div>
    <div className="mb-3 col-md-12">
      <label className="form-label" htmlFor="basic-icon-default-fullname3">Genre</label>
      <div className="input-group input-group-merge">
        <span id="basic-icon-default-fullname3" className="input-group-text">
        <i class="bi bi-gender-ambiguous"></i>
        </span>
        <div className='form-control bg-white' style={{ background:'#fff'  }} >
<Select value={selectedOption1} isSearchable={false}  onChange={(e) =>{handleChange1(e) }}  options={genderOption}  />
</div>
      </div>
    </div>
  </div>
</>

         

        
        );
      case 2:
        return (
          <>
  <div className="row mt-3">
    <div className="mb-3 col-md-6">
      <label className="form-label" htmlFor="dateOfBirth">Date de naissance</label>
      <div className="input-group input-group-merge">
      <span id="dateOfBirth" className="input-group-text">
          <i className="bx bx-calendar"></i>
        </span>
        <input
          type="date"
          className="form-control"
          id="dateOfBirthInput"
          name='date_naissance'
          value={personalInfo.date_naissance}
          onChange={handleChange}
        />
      </div>
    </div>
    <div className="mb-3 col-md-6">
      <label className="form-label" htmlFor="placeOfBirth">Lieu de naissance</label>
      <div className="input-group input-group-merge">
        <span id="placeOfBirth" className="input-group-text">
          <i className="bx bx-map"></i>
        </span>
        <input
          type="text"
          className="form-control"
          name='lieu_naissance'
          value={personalInfo.lieu_naissance}
          onChange={handleChange}
        />
      </div>
    </div>
    <div className="mb-3 col-md-12">

    <label className="form-label" htmlFor="country">Civilité</label>
    <div className="input-group col-md-12 input-group-merge">
        <span id="placeOfBirth" className="input-group-text">
        <i class="bi bi-flag"></i>
        </span>
  <div className='form-control bg-white' style={{ background:'#fff'  }} >
<Select  options={options}   value={value} name='civilite' onChange={ (e) => {  changeHandler(e) } } />
</div>
</div>
    </div>
    <div className="mb-3 col-md-12">
      <label className="form-label" htmlFor="matricule">Matricule</label>
      <div className="input-group input-group-merge">
        <span id="placeOfBirth" className="input-group-text">
        <i class="bi bi-person-vcard"></i>
        </span>
      <input
        type="text"
        className="form-control"
        id="matriculeInput"
        placeholder=""
        name='matricule'
        value={personalInfo.matricule}
        onChange={handleChange}
      />
      </div>
    </div>
  </div>
</>

           
        );
        case 3:
        return (
          <>
  <div className="row mt-3">
    <div className="mb-3 col-md-6">
      <label className="form-label" htmlFor="phoneNumber1">Téléphone</label>
      <div className="input-group input-group-merge">
        <span id="phoneNumber1" className="input-group-text">
          <i className="bx bx-phone"></i>
        </span>
        <input
          type="tel"
          id="basic-icon-default-phone1"
          className="form-control phone-mask"
          aria-describedby="phoneNumber1"
          name='telephone1'
          value={personalInfo.telephone1}
          onChange={handleChange}
        />
      </div>
    </div>
    <div className="mb-3 col-md-6">
      <label className="form-label" htmlFor="phoneNumber2">Téléphone</label>
      <div className="input-group input-group-merge">
        <span id="phoneNumber2" className="input-group-text">
          <i className="bx bx-phone"></i>
        </span>
        <input
          type="tel"
          id="basic-icon-default-phone2"
          className="form-control phone-mask"
          aria-describedby="phoneNumber2"
          name='telephone2'
          value={personalInfo.telephone2}
          onChange={handleChange}
        />
      </div>
    </div>
    <div className="mb-3 col-md-12">
      <label className="form-label" htmlFor="address">Adresse</label>
      <div className="input-group input-group-merge">
        <span id="address" className="input-group-text">
          <i className="bx bx-map"></i>
        </span>
        <input
          type="text"
          id="basic-icon-default-address"
          className="form-control"
          name='adresse'
          value={personalInfo.adresse}
          onChange={handleChange}
        />
      </div>
    </div>
  </div>
</>

        );
        case 4:
        return (

          <>
          <div className="row mt-3">
            <div className="mb-3 col-md-12">
              <label className="form-label" htmlFor="responsibleName">Responsable</label>
              <div className="input-group input-group-merge">
                <span id="responsibleIcon" className="input-group-text">
                <i class="bi bi-person-standing"></i>
                </span>
                <input
                  type="text"
                  id="responsibleName"
                  className="form-control phone-mask"
                  name='responsable'
                  value={personalInfo.responsable}
                  onChange={handleChange}
                />
              </div>
            </div>
      
            <div className="mb-3 col-md-12">
              <label className="form-label" htmlFor="responsibleQuality">Qualité du responsable</label>
              <div className="input-group input-group-merge">
              <span id="responsibleAddressIcon" className="input-group-text">
                  <i className="bi bi-question"></i>
                </span>
 <div className='form-control bg-white' style={{ background:'#fff'  }} >
<Select value={QualiteResponsable} isSearchable={false} onChange={ (e) => { handleChange4(e)}}  
options={[
  {value: 'Pere' , label: 'Père'},
  {value: 'Mere' , label: 'Mère'},
  {value: 'Tuteur' , label: 'Tuteur'},
]} 
/>
</div>
            </div>
            </div>
      
            <div className="mb-3 col-md-6">
              <label className="form-label" htmlFor="responsibleAddress">Adresse du responsable</label>
              <div className="input-group input-group-merge">
                <span id="responsibleAddressIcon" className="input-group-text">
                  <i className="bx bx-map"></i>
                </span>
                <input
                  type="text"
                  id="responsibleAddress"
                  className="form-control phone-mask"
                  name='adresse_responsable'
                  value={personalInfo.adresse_responsable}
                  onChange={handleChange}
                />
              </div>
            </div>
      
            <div className="mb-3 col-md-6">
              <label className="form-label" htmlFor="responsiblePhone">Téléphone du responsable</label>
              <div className="input-group input-group-merge">
                <span id="responsiblePhoneIcon" className="input-group-text">
                  <i className="bx bx-phone"></i>
                </span>
                <input
                  type="tel"
                  id="responsiblePhone"
                  className="form-control phone-mask"
                  name='phone_responsable'
                  value={personalInfo.phone_responsable}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </>

        );
        case 5:
        return (

          <>
          <div className="row mt-3">
            <div className="mb-3 col-md-6">
              <label className="form-label" htmlFor="bloodGroup">Groupe Sanguin</label>
              <div className="input-group input-group-merge">
        <span id="placeOfBirth" className="input-group-text">
        <i class="bi bi-droplet"></i>
        </span>
        <div className='form-control bg-white' style={{ background:'#fff'  }} >
<Select value={groupeSang}  isSearchable={false} onChange={(e)=> { handleChange3(e)}}  options={[{value:'A+', label:'A+' },{value:'B+', label:'B+' } , {value:'O+', label:'O+' },{value:'A-', label:'A-' },{value:'B-', label:'B-' } , {value:'O-', label:'O-' }, {value:'None', label:'Inconnu'}]}  />
</div>
            </div>
            </div>
      
             <div className="mb-3 col-md-6">
              <label className="form-label" htmlFor="disease">Souffre-t-il d'une maladie ?</label>
              <div className="input-group input-group-merge">
        <span id="placeOfBirth" className="input-group-text">
        <i class="bi bi-question"></i>
        </span>
              <div className='form-control bg-white' style={{ background:'#fff'  }} >
<Select value={maladi}  isSearchable={false} onChange={ (e) =>{  handleChange2(e) }}  options={[{value: 'Non', label:'Non' },{value: 'Oui', label:'Oui' } ]}  />
</div>
              </div>
            </div>
      
            {Ismalade && <div className="mb-3 col-md-12">
              <label className="form-label" htmlFor="whichDisease">Laquelle ?</label>
              <div className="input-group input-group-merge">
        <span id="c" className="input-group-text">
        <i class="bi bi-hospital"></i>
        </span>
                <input
                  type="text"
                  id="whichDisease"
                  className="form-control phone-mask"
                  name="malade"
                  value={personalInfo.malade}
                  onChange={handleChange}
                />
              </div>
            </div>}
          </div>
        </>
  
        );
        case 6:
        return (
<>
  <div className="row mt-3">
    <div className="card-body">
      <div className="d-flex align-items-start align-items-sm-center gap-4">
        <img
          src={ file || "/assets/img/avatars/2.png"}
          alt="user-avatar"
          className="d-block rounded"
          height="100"
          width="100"
          id="uploadedAvatar"
        />
        <div className="button-wrapper">
          <label htmlFor="upload" className="btn btn-primary me-2 mb-4" tabIndex="0">
            <span className="d-none d-sm-block">Choisir une photo</span>
            <i className="bx bx-upload d-block d-sm-none"></i>
            <input type="file" hidden id="upload" name='photo_profil' onChange={handleChange}  className="account-file-input" accept="image/png, image/jpeg" />
          </label>
          <button type="button" className="btn btn-outline-secondary account-image-reset mb-4">
          <i class="bi bi-camera d-block d-sm-none"></i>
            <span className="d-none d-sm-block"><i class="bi bi-camera"></i> Photo Live</span>
          </button>
          <p className="text-muted mb-0">Seuls JPG ou PNG sont acceptés. Taille max 800KO.</p>
        </div>
      </div>
    </div>

    <div className="mb-3 mt-3 col-md-12">
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        maxFiles={3}
        server="/api"
        name="files"
        labelIdle='Glisser et déposer vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
      />
    </div>
  </div>
</>


        );
        case 7:
        return (


      <div class="card-body mt-3">
        <div class="mb-3  col-12 mb-0">
          <div class="alert alert-success">
            <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
            <p class="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
          </div>
        </div>

      </div>
            
        );
      default:
        return null;
    }
  };


const isSmallScreen = true;
  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
        size='m'
      >

<Modal.Header closeButton>
          <Modal.Title>Preinscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  

      {RenderForm()}

      </Modal.Body>
      <Modal.Footer>
      <ProgressMobileStepper
     handleNext={nextStep}
     handleBack={prevStep}
     activeStep={actifStep}
     Steps={6}
     handleSubmit={handleSubmit}

    
    
    
    ></ProgressMobileStepper>
      </Modal.Footer>

    </Modal>
  );
};

export default Preincription;




