import React from 'react'

export default function Chat() {
  return (
      <div class="container bootstrap snippets bootdey">
      <div class="row">
      
      
      
      <div class="col-md-12 ">
      <div class="chat-message">
      <ul class="chat">

      <li class="left clearfix">
      <span class="chat-img pull-left">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="text-primary">John Doe</strong>
      <small class="float-right text-muted"><i class="bi bi-clock"></i> 12 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
      </div>
      </li>

      <li class="right clearfix">
      <span class="chat-img pull-right">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="primary-font">Sarah</strong>
      <small class="pull-right text-muted"><i class="fa fa-clock-o"></i> 13 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare dolor, quis ullamcorper ligula sodales at.
      </p>
      </div>
      </li>
      
      <li class="left clearfix">
      <span class="chat-img pull-left">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="primary-font">John Doe</strong>
      <small class="pull-right text-muted"><i class="fa fa-clock-o"></i> 12 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
      </div>
      </li>
      <li class="right clearfix">
      <span class="chat-img pull-right">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="primary-font">Sarah</strong>
      <small class="pull-right text-muted"><i class="fa fa-clock-o"></i> 13 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare dolor, quis ullamcorper ligula sodales at.
      </p>
      </div>
      </li>
      <li class="left clearfix">
      <span class="chat-img pull-left">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="primary-font">John Doe</strong>
      <small class="pull-right text-muted"><i class="fa fa-clock-o"></i> 12 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>
      </div>
      </li>
      <li class="right clearfix">
      <span class="chat-img pull-right">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="primary-font">Sarah</strong>
      <small class="pull-right text-muted"><i class="fa fa-clock-o"></i> 13 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare dolor, quis ullamcorper ligula sodales at.
      </p>
      </div>
      </li>
      <li class="right clearfix">
      <span class="chat-img pull-right">
      <img src="../assets/img/avatars/1.png" alt="User Avatar" />
      </span>
      <div class="chat-body clearfix">
      <div class="header">
      <strong class="primary-font">Sarah</strong>
      <small class="pull-right text-muted"><i class="fa fa-clock-o"></i> 13 mins ago</small>
      </div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare dolor, quis ullamcorper ligula sodales at.
      </p>
      </div>
      </li>
      </ul>
      </div>
      <div class="chat-box bg-white">
      <div class="input-group">
      <input class="form-control border no-shadow no-rounded" placeholder="Type your message here" />
      <span class="input-group-btn">
      <button class="btn btn-success no-rounded" type="button">Send</button>
      </span>
      </div>
      </div>
      </div>
      </div>
      </div>
  )
}
