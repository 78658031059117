import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PreviewIcon from '@mui/icons-material/Preview';
import { Box, Button, IconButton } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
const AdministrateursTable = ({handleView_ADM}) => {

  // Existing data and columns definitions remain the same
  const [AdminData, setAdminData] = useState(null);
  const [loading, setLoading] = useState(false);

// REQUEST AXIOS FOR DASH


useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(host.Backend, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
      });

      setAdminData(response.data);
    } catch (error) {
      console.error('Erreur lors de la requête API:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, []);


  const columns = useMemo(
    () => [
      {
        accessorKey: 'photo_profil',
        header: 'Photo',
        size: 90,
        Cell: ({ row }) => (
          <img
            src={host.Backendout+`${row.original.photo_profil}`}
            alt="Photo"
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          />
        ),
      },
      {
        accessorKey: 'nom',
        header: 'Nom',
        size: 200,
      },
      {
        accessorKey: 'prenom',
        header: 'Prenom',
        size: 250,
      },
      {
        accessorKey: 'genre',
        header: 'Genre',
        size: 30,
      },
      {
        accessorKey: 'date_naissance',
        header: 'Date de naissance',
        size: 100,
      },
      {
        accessorKey: 'lieu_naissance',
        header: 'Lieu de naissance',
        size: 150,
      },
      {
        accessorKey: 'civilite',
        header: 'Nationnalité',
        size: 100,
      },
      {
        accessorKey: 'matricule',
        header: 'Matricule',
        size: 100,
      },
      {
        accessorKey: 'telephone1',
        header: 'Telephone 1',
        size: 100,
      },
      {
        accessorKey: 'telephone2',
        header: 'Telephone 2',
        size: 100,
      },
      {
        accessorKey: 'adresse',
        header: 'Adresse',
        size: 150,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 200,
      },
      {
        accessorKey: 'poste_administratif',
        header: 'Poste administratif',
        size: 150,
      },
      {
        accessorKey: 'type_administrateur',
        header: 'Access',
        size: 70,
      },
      {
        accessorKey: 'create_at',
        header: 'Enregistré le',
        size: 50,
      },
      {
        accessorKey: 'Actions',
        header: 'Actions',
        size: 50,
        Cell: ({ row }) => (
          <Button variant="outlined"  onClick={(id) => {
            handleView_ADM(row.original.id)
          
          }} 
          startIcon={<VisibilityIcon />}
        >
          </Button>
        ),        
      },

      
    ],
    [],
  );

  const handleButtonClick = (rowData) => {
    // Implement your functionality here using rowData from the clicked row
    console.log('Button clicked for row:', rowData);
  };

  const data = AdminData ? AdminData : [];

  const table = useMaterialReactTable({
    columns,
    data,
    localization:{MRT_Localization_FR},
  });
  return (

    <MaterialReactTable

      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering

      enableSelectAll={false}

      initialState= {{ columnVisibility: { photo_profil: false ,civilite: false ,date_naissance: false ,adresse_responsable: false , adresse: false , telephone1: false , telephone2: false , type_administrateur: false , create_at : false , lieu_naissance : false} }}

      localization={MRT_Localization_FR}

    />

  );
};

export default AdministrateursTable;
