import React, { useState } from 'react'
import EleveTable from './Composant/Eleves/EleveTable'
import Preincription from './Composant/Eleves/Preincription';

export default function Accueil({User, DashData ,handleView , Eleve , Enseignant , Admin , setRDash }) {

  const [ispreinscription , setispreinscription ] = useState(false);

 const preinscription = () =>{
  setispreinscription(true)

 }

 const close_preinscription = () =>{
  setispreinscription(false)

 }
  return (
    <div class="container-xxl flex-grow-1 container-p-y">
    <div class="row">
      <div class="col-lg-8 col-md-8 mb-4 order-0">
        <div class="card">
          <div class="d-flex align-items-end row">
            <div class="col-sm-7">
              <div class="card-body">
                    {User ? (
      <h5 class="card-title text-primary">Bienvenue {User.nom} ! 🎉</h5>
      ) : (
        <p> </p>
      )} 
                <p class="mb-4">
                Savane 2.0 votre application de gestion scolaire innovante, <span class="fw-bold">propulsée par l'intelligence artificielle</span>, pour une expérience éducative optimale et personnalisée !
                </p>

              </div>
            </div>
            <div class="col-sm-5 text-center text-sm-left">
              <div class="card-body pb-0 px-0 px-md-4">
                <img
                  src="../assets/img/illustrations/go2ai.png"
                  height="150"
                  alt="View Badge User"
                  data-app-dark-img="illustrations/man-with-laptop-dark.png"
                  data-app-light-img="illustrations/man-with-laptop-light.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 mb-4 ">
            <div class="card ">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <i class="bi bi-person card-icon" style={{color:'#c2185b'}}></i>
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt4"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                      <a class="dropdown-item" onClick={Eleve}>Gestion des eleves</a>
                    </div>
                  </div>
                </div>
                <span class="d-block mb-1">Effectif Eleves</span>
                <h3 class="card-title text-nowrap mb-2">{DashData && DashData[0]?.nombreEleve}</h3>
               
               
              </div>
            </div>
          </div>



      
      <div class="col-12 ">

        <div class="row">
       
          <div class="col-lg-4 col-md-6 mb-4 ">
            <div class="card ">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <i class="bi bi-person-check card-icon" style={{color:'#448aff'}}></i>
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt4"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                      <a class="dropdown-item" onClick={Eleve} >Inscrire un eleves</a>
                    </div>
                  </div>
                </div>
                <span class="d-block mb-1">Effectif Inscrit</span>
                <h3 class="card-title text-nowrap mb-2">{DashData && DashData[0]?.nombreInscrit}</h3>
                
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-4">
            <div class="card">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                    <i class="bi bi-person-workspace card-icon" style={{color:'#689f38'}}></i>
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt4"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                      <a class="dropdown-item" onClick={Enseignant}>Gestion des enseignants</a>
                    </div>
                  </div>
                </div>
                <span class="d-block mb-1">Effectif Enseignants</span>
                <h3 class="card-title text-nowrap mb-2">{DashData && DashData[0]?.nombreEnseignant}</h3>
               
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-4 ">
            <div class="card">
              <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                  <div class="avatar flex-shrink-0">
                  <i class="bi bi-person-gear card-icon" style={{color:'#e64a19'}}></i>
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn p-0"
                      type="button"
                      id="cardOpt4"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                      <a class="dropdown-item" onClick={Admin} >Gestion des administrateurs</a>
                    </div>
                  </div>
                </div>
                <span class="d-block mb-1">Effectif Administrateur</span>
                <h3 class="card-title text-nowrap mb-2">{DashData && DashData[0]?.nombreAdministrateur}</h3>
               
              </div>
            </div>
          </div>

           </div>

      </div>
    </div>
    {ispreinscription && 
<Preincription
Ouvrir={preinscription}
Fermer = {close_preinscription}
setRDash={setRDash}
></Preincription>}
    {DashData &&  <EleveTable  Preins={preinscription} Eleve={DashData[0]?.Eleves}  handleView={handleView}/>}

  </div>
  )
}
