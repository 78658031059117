import { Button } from 'bootstrap';

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

const ListeParClasse = ({Ouvrir, Fermer}) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [selectedOption1, setselectedOption1] = useState([]);
  const [selectedOption2, setselectedOption2] = useState([]);
  const [ClasseOption, setClasseOption] = useState([]);
  const [ClasseAproprie , setClasseAproprie] = useState([])
  const [ACOption , setACOption] = useState([])
  useEffect( async () => {
    try {

      const response = await axios.get(host.Backend+`Classe/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
      });

      setClasseAproprie(response.data);
    } catch (error) {
      console.error('Erreur lors de la requête API:', error);
    } 

}, []); 

  useEffect(() => {
    const options = ClasseAproprie.map((element) => ({
      value: element.id,
      label: `${element.Niveau} ${element.Classe}`
    }));
  
    setClasseOption(options);
  }, []); 
  
  const [ACAproprie , setACAproprie] = useState([])

  const  handleupAC = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`AnneeAcademiquebyclasse/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setACAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };
  useEffect(() => {
    // Assuming Eleve is an array
    const options = ACAproprie.map((element) => ({
      value: element.id,
      label: `${'Inscription '} ${element.annee_academique}`
    }));
  
    setACOption(options);
  }, []); 

  const handleChange1= value => {
    setselectedOption1(value)
    setFiles({ ...files, 'classe_id' : value.value })
  }

  const handleChange2= value => {
    setselectedOption2(value)
    setFiles({ ...files, 'AC_id' : value.value })
  }
  
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFiles({ ...files, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
   
   console.log(files)
   
};


  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">
      <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Classe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
       <Select value={selectedOption1} isSearchable={true}  onChange={(e) =>{handleChange1(e) }}  options={ClasseOption}  />
      </div>
     </div>
   </div>

   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Inscription</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
       <Select value={selectedOption2} isSearchable={true}  onChange={(e) =>{handleChange2(e) }}  options={ACOption}  />
      </div>
     </div>
   </div>

   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={handleSubmit} class="btn btn-primary ">Imprimer</span>
   </div>
   </div>
            </form>

        
        );

      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Liste par classe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default ListeParClasse;




