import { Button } from 'bootstrap';
import React, { useState , useEffect} from 'react';

import { FilePond, registerPlugin } from 'react-filepond'

import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import TableAllnote from './Note/TableAllnote';
import host from '../../../host';

const authToken = localStorage.getItem('authToken');

const EnregistrementEmmergement = ({Notes, Fermer , noteTable, noteInfo , setnoteInfo, setNotesTable}) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [AllNiveau , setAllNiveau] = useState([])

  useEffect(() => {
  
    const fetchDataa = async () => {
      setnoteInfo([])
      setNotesTable(false)
    };
  
    fetchDataa();
  }, []);
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'AllNiveau/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setAllNiveau(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);

  const [ClasseAproprie , setClasseAproprie] = useState([])

  const  handleupClasse = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Classebyniveau/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setClasseAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };

  const [GroupeAproprie , setGroupeAproprie] = useState([])

  const  handleupGroupe = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Groupebyclasse/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setGroupeAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };


  const [MatiereAproprie , setMatiereAproprie] = useState([])

  const  handleupMatiere = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Matiereattbyclasse/${e.target.value}/${noteInfo.groupe}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setMatiereAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };

  
  const [Anneeaproprie , setAnneeaproprie] = useState([])

  const  handleannee = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`AnneeAcademiquebyniveau/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setAnneeaproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };

  const [Semestreaproprie , setSemestreaproprie] = useState([])

  const  handleSemestre = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Semestrebyac/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setSemestreaproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };


  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setnoteInfo({ ...noteInfo, [name]: value });
  };



  const handleSubmit = (e) => {
    console.log('Informations personnelles:', setnoteInfo);
    noteTable()
  };

  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">

       <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Niveau</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
                  <select className='form-select' 
  onChange={(e) => {
    handleChange(e);
    handleupClasse(e);
    handleannee(e)
  }} name='niveau'  value={noteInfo.niveau}>
                        <option value={null}>Chosir...</option>   
                          {AllNiveau.map((niveau) => (
                              <option key={niveau.id} value={niveau.id}>
                                {niveau.nom}
                              </option>
                          ))}
                          </select>
     </div>
   </div>

   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Classe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
                               <select className='form-select'  name='classe' onChange={(e) => {
    handleChange(e);
    handleupGroupe(e);
  }} value={noteInfo.classe} required>
                    <option value={null}>Chosir...</option> 
                      {ClasseAproprie.map((classe) => (
                        <option value={classe.id}>{classe.classe}</option>
                      ))}
                    </select>
     </div>
   </div>

   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Groupe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       <select className='form-select'  name='groupe' onChange={(e) => {
    handleChange(e);
  }} value={noteInfo.groupe} required>
                    <option value={null}>Chosir...</option> 
                      {GroupeAproprie.map((groupe) => (
                        <option value={groupe.id}>{groupe.groupe}</option>
                      ))}
                    </select>
     </div>
   </div>


   <div class="mt-3 col-md-12 d-flex justify-content-center">

   <span onClick={Fermer} class="btn btn-outline-secondary me-2">Fermer</span>
     <span  onClick={nextStep} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </form>

        
        );

      case 2:
        return (
            <form >
            <div class="row mt-3">
            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Annne Academique</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <select className='form-select'  name='annee_academique' onChange={(e) => {
    handleChange(e);
    handleSemestre(e);
    handleupMatiere(e);
  }} value={noteInfo.annee_academique} required>
                    <option value={null}>Chosir...</option> 
                      {Anneeaproprie.map((annee_academique) => (
                        <option value={annee_academique.id}>{annee_academique.annee_academique}</option>
                      ))}
                    </select>

     </div>
   </div>
   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Semestre</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <select className='form-select'  name='semestre' onChange={(e) => {
    handleChange(e);
  }} value={noteInfo.semestre} required>
                    <option value={null}>Chosir...</option> 
                      {Semestreaproprie.map((Semestre) => (
                        <option value={Semestre.id}>{Semestre.semestre}</option>
                      ))}
                    </select>


     </div>
   </div>

   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Matiere</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <select className='form-select'  name='matiere' onChange={(e) => {
    handleChange(e);
  }} value={noteInfo.matiere} required>
                    <option value={null}>Chosir...</option> 
                      {MatiereAproprie.map((matiere) => (
                        <option value={matiere.id}>{matiere.matiere} {matiere.annee_academique}</option>
                      ))}
                    </select>


     </div>
   </div>


   
     <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={handleSubmit} class="btn btn-primary me-2 ">Envoyer</span>
   </div>
</div>
            </form>
        );

        case 4:
        return (


      <div class="card-body mt-3">
        <div class="mb-3  col-12 mb-0">
          <div class="alert alert-success">
            <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
            <p class="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
          </div>
        </div>

      </div>
            
        );
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Notes}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Gestion des notes </Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default EnregistrementEmmergement;




