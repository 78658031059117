import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    Titre: 'Enfant noir',
    Auteur: 'Camara laye',
    Date: '12-12-2023',
    Genre: 'Livre',
    Format: 'PDF',
    Actions: 'SUP',
  },
];

const Bibliotheque = () => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Titre', //access nested data with dot notation
        header: 'Titre',
        size: 150,
      },
      {
        accessorKey: 'Auteur',
        header: 'Date',
        size: 150,
      },
      {
        accessorKey: 'Date', //normal accessorKey
        header: 'Date',
        size: 200,
      },
      {
        accessorKey: 'Genre',
        header: 'Genre',
        size: 150,
      },
      {
        accessorKey: 'Format',
        header: 'Format',
        size: 150,
      },
      {
        accessorKey: 'Actions',
        header: 'Actions',
        size: 150,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return ( <div>
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Bibliotheque</h4>

<p>
Publier des documment des pour aide vos eleves a rehausee leurs niveau.
</p>



<div class="d-flex flex-wrap" id="icons-container">
<div class="card icon-card cursor-pointer text-center mb-4 mx-2">
<div class="card-body">
<i class="bx bxl-adobe mb-2"></i>
<p class="icon-name text-capitalize text-truncate mb-0">Contenu existant</p>
</div>
</div>
<div class="card icon-card cursor-pointer text-center mb-4 mx-2">
<div class="card-body">
<i class="bx bxl-algolia mb-2"></i>
<p class="icon-name text-capitalize text-truncate mb-0">Ajouter des fichiers</p>
</div>
</div>
<div class="card icon-card cursor-pointer text-center mb-4 mx-2">
<div class="card-body">
<i class="bx bxl-algolia mb-2"></i>
<p class="icon-name text-capitalize text-truncate mb-0">Ajouter un QCM</p>
</div>
</div>
<div class="card icon-card cursor-pointer text-center mb-4 mx-2">
<div class="card-body">
<i class="bx bxl-audible mb-2"></i>
<p class="icon-name text-capitalize text-truncate mb-0">Statisque de lecture</p>
</div>
</div>



</div>




<div className=" p-2 table-responsive">
<MaterialReactTable table={table} />
</div>
</div>) ;
};

export default Bibliotheque;
