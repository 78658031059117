import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import axios from 'axios';
import {
  MRT_BottomToolbar,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { Box, Button, IconButton } from '@mui/material';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

export default function Table_Groupe({P_Groupe}) {
  const [GroupeData, setGroupeData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        const response = await axios.get(host.Backend+'Groupe/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setGroupeData(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
    const columns = useMemo(
        () => [
            {
              accessorKey: 'groupe',
              header: 'Groupe',
              size: 150,
            },
            {
              accessorKey: 'classe',
              header: 'Classe',
              size: 150,
            },
          {
            accessorKey: 'Niveau',
            header: 'Niveau',
            size: 150,
          },
          {
            accessorKey: 'create_at',
            header: 'Date Insertion',
            size: 150,
          },
        ],
        [],
      );
    
      const data = GroupeData ? GroupeData : []
    

      return <MaterialReactTable 
      
      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering

      enableSelectAll={false}

      initialState={{ showColumnFilters: false, showGlobalFilter: true }}

      localization={MRT_Localization_FR}
      renderTopToolbarCustomActions={(props) => (
        <Box sx={{ display: 'flex', gap: '1rem', p: '2px' }}>
  
          <IconButton 
            onClick={
              P_Groupe
            }
            vari>
          <AddBoxIcon  />
          
        </IconButton>
  
          <IconButton
          onClick={() => {
            window.print();
          }}
        >
          <PrintIcon />
        </IconButton>
        </Box>
      )}
      
      />;
}
