import React, { useState , useEffect } from 'react'
import Preincription from './Eleves/Preincription'
import Inscription from './Eleves/Inscription';
import EleveTable from './Eleves/EleveTable';
import axios from 'axios';
import host from '../../../host';
const authToken = localStorage.getItem('authToken');


export default function Eleve1({DashData , linkInsertEleve , handleView , dashboard, setRDash}) {
  const [ispreinscription , setispreinscription ] = useState(false);
  const [AllNiveau , setAllNiveau] = useState([])
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'AllNiveau/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setAllNiveau(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);


 const preinscription = () =>{
  setispreinscription(true)

 }
 const close_preinscription = () =>{
  setispreinscription(false)

 }

 const [isinscription , setisinscription ] = useState(false);
 const inscription = () =>{
  setisinscription(true)

 }
 const close_inscription = () =>{
  setisinscription(false)

 }
  
  return (
    <div> 
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light" onClick={dashboard} >Dashboard /</span> Gestion des eleves</h4>

<p>
Centralisez la gestion des élèves : profils, Preincription, Inscription... Tout ce dont vous avez besoin, à portée de clic.
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={preinscription} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person-plus"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Pré-inscription des élèves</p>
      </div>
    </div>
    <div  onClick={inscription} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Inscription des élèves</p>
      </div>
    </div>
    
    
  </div>
{ispreinscription && 
<Preincription
Ouvrir={preinscription}
Fermer = {close_preinscription}
setRDash = {setRDash}
></Preincription>}

{isinscription && 
<Inscription
Ouvrir={inscription}
Fermer = {close_inscription}
Eleve={DashData[0]?.Eleves}
AllNiveau= {AllNiveau}
setRDash = {setRDash}
></Inscription>}


{DashData &&  <EleveTable  Preins={preinscription} Eleve={DashData[0]?.Eleves}  handleView={handleView}/>}
</div>


  )
}
