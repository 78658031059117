import { Button } from 'bootstrap';

import React, { useEffect, useState } from 'react'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import Select from 'react-select'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

const Payements = ({Ouvrir, Fermer, setisViewEN , setViewId}) => {
  const [step, setStep] = useState(1);
  const [EnseignantOption, setEnseignantOption] = useState([]);
  const [selectedOption1, setselectedOption1] = useState([]);
  const [Enseignant, setEnseignant] = useState([]);
  const [EnseignantInfo , setEnseignantInfo] = useState([])

  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'Enseignant/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setEnseignant(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);

  useEffect(() => {
    // Assuming Eleve is an array
    const options = Enseignant.map((element) => ({
      value: element.id,
      label: `${element.nom} ${element.prenom} ${element.matricule}`
    }));
  
    setEnseignantOption(options);
  }, [Enseignant]); // Add Eleve as a dependency if it's coming from props or state
  
 


  const handleChange= value => {
    setselectedOption1(value)
    setEnseignantInfo({ ...EnseignantInfo, 'EN_id' : value.value })
  }
  
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
   
   console.log(EnseignantInfo);
   setisViewEN(true);
   setViewId(EnseignantInfo.EN_id);
   Fermer();

};


  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">
      <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Eleve</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
       <Select value={selectedOption1} isSearchable={true}  onChange={(e) =>{handleChange(e) }}  options={EnseignantOption}  />
      </div>
     </div>
   </div>

   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={handleSubmit} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </form>

        
        );

 
  
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Payement Enseignant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default Payements;




