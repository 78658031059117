import React, { useState , useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');

function AddNote({noteInfo, noteTable}) {
  const [studentData, setStudentData] = useState([]);
  const [evaluationData, setevaluationData] = useState([]);
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+`DataForNotes/${noteInfo.niveau}/${noteInfo.classe}/${noteInfo.groupe}/${noteInfo.annee_academique}/${noteInfo.matiere}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setStudentData(response.data.note);

        setevaluationData(response.data.eva);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleNoteChange = (Inscription_id, value) => {
    const updatedStudentData = studentData.map((student) =>
      student.Inscription_id === Inscription_id ? { ...student, Notes: value } : student
    );
    setStudentData(updatedStudentData);
  };
  const handleChange = (e) =>{
    setevaluationData({ ...evaluationData, [e.target.name]: e.target.value });
  }
  const handleSave = () => {
    setShowModal(true);
  };

  const handleCloseModal = async () => {
    const data = {
        "eva" : evaluationData,
    "note" : studentData
}
    console.log('Enregistrement des notes:', data);

    try {
        // Utilisez Axios pour envoyer les données du formulaire au serveur
        const response = await axios.post(host.Backend+'Evaluation/', data);
        console.log(response.data);
       

    } catch (error) {
        // Gérez les erreurs ici
        console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
    setShowModal(false);
  };

   
  return (
    <div>
        <h2>{noteInfo.matiere}</h2>
        <TableContainer>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentData.map((student) => (
              <TableRow key={student.Inscription_id}>
                <TableCell>{student.Inscription_id}</TableCell>
                <TableCell>{student.eleve_nom}</TableCell>
                <TableCell>{student.eleve_prenom}</TableCell>
                <TableCell>
                  <FormControl
                    type="number"
                    name='Notes'
                    value={student.Notes}
                    onChange={(e) => handleNoteChange(student.Inscription_id, e.target.value)}
                    style={{ maxWidth: '100px' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="primary" onClick={handleSave}>
        Save
      </Button>
       &nbsp;&nbsp;&nbsp;
      <Button variant="danger" onClick={noteTable}>
        Quitter
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Evaluation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="row mt-3">
        <div class="mb-3 col-md-6">
    <label for="classe" class="form-label">Bareme</label>
                  <select className='form-select' 
  onChange={(e) => {
    handleChange(e)
  }} name='sur'  value={evaluationData.sur}>
                        <option value={null}>Chosir...</option> 
                        <option value={5}>5</option> 
                        <option value={10}>10</option> 
                        <option value={20}>20</option> 
                          </select>
     </div>
     <div class="mb-3 col-md-6">
    <label for="classe" class="form-label">Type Evaluation</label>
                  <select className='form-select' 
  onChange={(e) => {
    handleChange(e)
  }} name='type_evaluation'  value={evaluationData.type_evaluation}>
                        <option value={null}>Chosir...</option> 
                        <option value={'Interogation'}>Interrogation</option> 
                        <option value={'Devoir'}>Devoir</option> 
                          </select>
     </div>
     <div className="mb-3 col-md-12">
      <label className="form-label" htmlFor="basic-icon-default-fullname1">Date</label>
        <input
          type="date"
          className="form-control"
          id="basic-icon-default-fullname1"
          name="date_evaluation"
          value={evaluationData.date_evaluation}
          onChange={handleChange}
        />
      </div>
     </div>
  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddNote;
