import axios from "axios";
import React, { useState } from "react";
import host from "../host";


const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const [Alertt, setAlertt] = useState('Connectez vous a votre compte pour commencer l\'avanture');
  const authToken = localStorage.getItem('authToken');

  axios({
    method: 'get',
    url: host.Backend+'VerifyToken/',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${authToken}`  
    },
  }) 
  .then(response => {

    if (response.data.valide === 1 ){
      window.location.href = '/dashboard';
    }
      
  })
  .catch(error => {
    console.log('Error:', error);
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    axios({
      method: 'post',
      url: host.Backend+'Login/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        username: formData.username,
        password: formData.password
      },
    })
      .then(response => {
        const tokenObject = {
          'token': response.data.token,
          'user_id': response.data.user_id
        }
        localStorage.clear()
        localStorage.setItem('authToken', tokenObject.token)
        localStorage.setItem('user_id', tokenObject.user_id)
        window.location.href = '/dashboard';
      })
      .catch(error => {
        console.log('Error:', error);
        setAlertt('Le serveur refuse l\'access')
      });
  };
  
  

  return (
    <div className="container-xxl">
    <div className="authentication-wrapper authentication-basic container-p-y">
      <div className="authentication-inner">
        <div className="card">
          <div className="card-body">
            <div className="app-brand justify-content-center">
              <a href="index.html" className="app-brand-link ">
                <span className="app-brand-logo demo">
                  
                   <img src="../assets/img/favicon/favicon.png" alt="logo savane" width="35" />
                </span>
                <span className="app-brand-text demo text-body fw-bolder">avane</span>
              </a>
            </div>
            <h4 className="mb-2">Welcome to Savane! 👋</h4>
            <p className="mb-4">{Alertt}</p>
    
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label for="email" className="form-label">Votre Nom d'utilisateur</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="username"
                  value={formData.username}
                  onChange={handleInput}
                  placeholder="Enter your email or username"
                  autofocus
                />
              </div>
              <div className="mb-3 form-password-toggle">
                <div className="d-flex justify-content-between">
                  <label className="form-label" for="password">Mot de passe</label>
                  <a href="auth-forgot-password-basic.html">
                    <small>Mot de passe oublie?</small>
                  </a>
                </div>
                <div className="input-group input-group-merge">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    name="password"
                    value={formData.password}
                    onChange={handleInput}
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="password"
                  />
                  <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="remember-me" />
                  <label className="form-check-label" for="remember-me"> Se Rappeler de moi </label>
                </div>
              </div>
              <div className="mb-3">
                <button className="btn btn-primary d-grid w-100" type="submit">Connecter</button>
              </div>
            </form>
    
            <p className="text-center">
              <span>Nouveau sur cette plateforme ?</span>
              <a href="auth-register-basic.html">
                <span>Demander un compte</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  );
};

export default Login;


