import React, { useState , useEffect} from 'react';
import axios from "axios";
import Aside from './Dashboards/Aside';
import Nav from './Dashboards/Nav';
import Footer from './Dashboards/Footer';
import Accueil from './Dashboards/Accueil';
import Rapport1 from './Dashboards/Composant/Rapport';
import Eleve1 from './Dashboards/Composant/Eleve';
import Enseignant1 from './Dashboards/Composant/Enseignant';
import Notes from './Dashboards/Composant/Notes';
import Emploi1 from './Dashboards/Composant/Emploi';
import Bibliotheque from './Dashboards/Composant/Bibliotheque';
import Reglage from './Dashboards/Composant/Reglage';
import Chat from './Dashboards/Composant/chat';
import Administrateurs from './Dashboards/Composant/Administration';
import Cours from './Dashboards/Composant/Cours';
import Emmergements from './Dashboards/Composant/Emmergements';
import TableAllnote from './Dashboards/Composant/Note/TableAllnote';
import Addnote from './Dashboards/Composant/Note/Addnote';
import { EleveProfil } from './Dashboards/Composant/View/EleveProfil';
import { ENProfil } from './Dashboards/Composant/View/ENProfil';
import { ADMProfil } from './Dashboards/Composant/View/ADMProfil';
import Comptabilite from './Dashboards/Composant/Comptabilite/Comptabilite';
import EvaGest from './Dashboards/Composant/Note/EvaGest';
import AddCompo from './Dashboards/Composant/Note/Addcompo';
import host from '../host';
import Loader from './loader';
const authToken = localStorage.getItem('authToken');
const user_id = localStorage.getItem('user_id');

const Dashboard = () => {






  const [asider, setAsider] = useState(false);

  const [isclose , setisclose] = useState(false);
  const [accueil, setAccueil] = useState(true);
  const [insertEleve, setInsertEleve] = useState(false);
  const [insertEnseignant, setInsertEnseignant] = useState(false);
  const [isRapport, setIsRapport] = useState(false);
  const [isEleve, setIsEleve ]= useState(false);
  const [isEnseignant, setisEnseignant] = useState(false);
  const [isEmploi, setisEmploi] = useState(false);
  const [isNotes, setisNotes] = useState(false);
  const [isBiblio, setisBiblio] = useState(false);
  const [isReglage, setisReglage] = useState(false);
  const [isComptabilite, setisComptabilite] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isCom, setIscom] = useState(false);
  const [Isemmergements, setIsemmergements] = useState(false);
  const [Iscours, setIscours] = useState(false);
  const [user, setUser] = useState(null);
  const [DashData, setDashData] = useState(null);
  const [NotesTable , setNotesTable] =  useState(false)
  const [EvaTable , setEvaTable] = useState(false)
  const [addCompo , setaddCompo] = useState(false)

  const [noteInfo, setnoteInfo] = useState({});
  const [addnote , setaddnote] = useState(false)
  const [ViewEleve , setViewEleve] = useState(false)
  const [ViewEN , setViewEN] = useState(false)
  const [ViewADM , setViewADM] = useState(false)
  const [IdToview , setIdToview] = useState()
  const [RDash , setRDash] = useState(false)

// REQUEST AXIOS FOR DASH


const [loading, setLoading] = useState(false);
useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);
      
      const response = await axios.get(host.Backend+'Dashboard/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
      });

      setDashData(response.data);
      setUser(response.data[0].user[0])
    } catch (error) {
      console.error('Erreur lors de la requête API:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [isEleve , accueil , RDash ]);


















function IsPhone() {
  return window.innerWidth <= 1200;
}
const menuBerger = () => {
  setAsider(!asider);
};
function closeAll() {
  setaddCompo(false)
  setEvaTable(false)
  setViewEN(false)
  setViewADM(false)
  setViewEleve(false)
  setaddnote(false);
  setNotesTable(false);
  setIscours(false);
  setIsemmergements(false);
  setIscom(false);
  setisNotes(false);
  setisComptabilite(false);
  setisAdmin(false);
  setisReglage(false);
  setisBiblio(false);
  setisEmploi(false);
  setisEnseignant(false);
  setIsEleve(false);
  setIsRapport(false);
  setAccueil(false);

  if (IsPhone() === true) {
    setAsider(false);
  }
}
const dashboard = () =>{
  closeAll()
  setAccueil(true);
}
  const rapport = () => {
    closeAll();
    setIsRapport(true);
  };
  const eleve = () => {
    closeAll()
    setIsEleve(true)    
  };
  const enseignant = () => {
    closeAll()
    setisEnseignant(true)
  };

  const emploi = () => {
    setisEmploi(true);    
    setisAdmin(false);
    setNotesTable(false)
    setisReglage(false);
    setisBiblio(false);
    setisEnseignant(false);
    setIsEleve(false);
    setIsRapport(false);
    setViewEleve(false)
    setAccueil(false);
    setisComptabilite(false);
    setaddnote(false);
    setIscom(false);
    setIsemmergements(false);
    setIscours(false);
    if(IsPhone() == true){
      setAsider(false);
    }
  }
  const notes = () => {
    setisNotes(true);
    setisclose(false)
    if(IsPhone() == true){
      setAsider(false);
    }
  };
  const biblio = () =>{
    closeAll()
    setisBiblio(true); 
  };
  const reglage = () =>{
    closeAll()
    setisReglage(true);  
  };
  const admin = () =>{
    closeAll()
    setisAdmin(true);
  };
  const comptabilite = () =>{
    closeAll()
    setisComptabilite(true);
  };
  const com = () =>{
    closeAll()
    setIscom(true);
  };

  const emmergements = () =>{

    closeAll()
    setIsemmergements(true);
  };

  const cours = () =>{
    closeAll()
    setIscours(true);
  };

  const noteTable = () => {
    closeAll()
    setNotesTable(true)

  }

  const addNote = () => {
    closeAll()
    setaddnote(true);

  };
  const Evaview =() => {
     closeAll()
     setEvaTable(true)
  }
  const handleView = (id) => {

    closeAll()
    setIdToview(id)
    setViewEleve(true)
  };
  const handleView_EN = (id) => {
    closeAll()
    setIdToview(id)
    setViewEN(true)
  };
  const handleView_ADM = (id) => {
    closeAll()
    setIdToview(id)
    setViewADM(true)
  };

  const addcompo = () => {
    closeAll()
    setaddCompo(true)
  };
  const linkInsertEleve = () => {
    setInsertEleve(true);
  };

  const linkInsertEnseignant = () => {
    setInsertEnseignant(true);
  };
  const fermer = () => {
    setisNotes(false);
    setisclose(false)

  };

  return (
    <div className="layout-container">
      <Aside
        Menu={menuBerger}
        Rapports={rapport}
        LinkInsertElevee={linkInsertEleve}
        Asider={asider}
        Accueil={accueil}
        Eleve={eleve}
        Enseignant={enseignant}
        Emploi={emploi}
        Notes={notes}
        Biblio={biblio}
        Reglage={reglage}
        Comptabilite={comptabilite}
        Admin={admin}
        Com={com}
        Emmergements={emmergements}
        Cours={cours}
        Dashboard={dashboard}
        IsEleve ={isEleve}
        IsRapport={isRapport}
        isEnseignant ={isEnseignant}
        Iscours = {Iscours}
        isAdmin = {isAdmin}
        Isemmergements = {Isemmergements}
        isEmploi ={isEmploi}
        isNotes ={isNotes}
        isComptabilite ={isComptabilite}
        isReglage ={isReglage}
        Iscom={isCom}
      />
      <div className={`layout-page  ${asider ? '' : 'page-marge'} `}>
        <Nav Menu={menuBerger} User={user} DashData={DashData} />
        {accueil && <Accueil 
        handleView={handleView} 
        User={user}  
        DashData={DashData}
        Eleve={eleve}
        Enseignant={enseignant}
        Admin={admin}
        setRDash={setRDash}
         />}
        <div class="container-xxl flex-grow-1 container-p-y mt-2">
          <div class="row">
            <div class="col-lg-12 mb-4 order-0">
              {isRapport && <Rapport1  User={user}  />}
              {isEleve && <Eleve1  
              linkInsertEleve={linkInsertEleve}
              User={user} 
              DashData={DashData}
              handleView={handleView}
              dashboard={dashboard}
              setRDash={setRDash}
               />}
              {isEnseignant && <Enseignant1 handleView_EN={handleView_EN} User={user} />}
              {isEmploi && <Emploi1  User={user}  />}
              {isNotes && <Notes 
              Fermer={fermer}
              Isclose = {isclose}
              Notes={notes}
              User={user} 
              noteTable = {noteTable}
              setnoteInfo ={setnoteInfo}
              noteInfo = {noteInfo}
              dashboard = {dashboard}
              setNotesTable={setNotesTable}

              />}
           {isBiblio &&   <Bibliotheque 
                User={user} 
              />}
              {Iscours && <Cours   User={user} ></Cours>}
              {Isemmergements && <Emmergements  User={user} ></Emmergements>}
              {isReglage &&   <Reglage   User={user} 
              Fermer={fermer}
              Isclose = {isclose}
              />}

{isComptabilite &&   <Comptabilite   User={user} 
              
              />}

{isAdmin &&   <Administrateurs 
  User={user} 
  handleView_ADM={handleView_ADM}
              />}

{isCom &&   <Chat  User={user} ></Chat> }

{NotesTable &&  <TableAllnote addcompo = {addcompo} addNote = {addNote} Evaview={Evaview} noteInfo = {noteInfo} ></TableAllnote> }

 {addnote && <Addnote addNote = {addNote}  noteInfo = {noteInfo} 
              noteTable={noteTable} ></Addnote>} 

 {addCompo && <AddCompo addcompo = {addcompo}  noteInfo = {noteInfo} 
              noteTable={noteTable} ></AddCompo>} 

 {EvaTable && <EvaGest noteInfo = {noteInfo} 
              noteTable={noteTable} ></EvaGest>}             
 {ViewEleve &&  <EleveProfil 
              id={IdToview}
              Eleve={eleve}
               /> }
 {ViewEN && <ENProfil id={IdToview} ></ENProfil>}
 {ViewADM && <ADMProfil id={IdToview}  />}
 {loading && <Loader ></Loader>}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
