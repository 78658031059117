import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
export default function AnneeAcademique({Ouvrir , Fermer,P_AllNiveau}) {
  const [annees, setAnnees] = useState([]);
  const anneeDebut = new Date().getFullYear();
  const anneeFin = 2030;

  useEffect(() => {
    const anneesOptions = [];
    for (let annee = anneeDebut; annee <= anneeFin; annee++) {
      const anneeSuivante = annee + 1;
      const anneeAcademique = `${annee}-${anneeSuivante}`;
      anneesOptions.push(<option key={anneeAcademique}>{anneeAcademique}</option>);
    }
    setAnnees(anneesOptions);
  }, []);
    const [formData, setFormData] = useState({
  
      niveau_id: '',
      annee_academique: '',
      debut: '',
      fin: ''
  
    });
    const handleChange = (e) => {

      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const [ClasseAproprie , setClasseAproprie] = useState([])
    const  handleupClasse = async (e) => {
  
          try {
      
            const response = await axios.get(host.Backend+`Classebyniveau/${e.target.value}/`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
              },
            });
      
            setClasseAproprie(response.data);
          } catch (error) {
            console.error('Erreur lors de la requête API:', error);
          } 
      
  
    };
    const handleSubmit = async (e) => {
      e.preventDefault();


      try {
        // Utilisez Axios pour envoyer les données du formulaire au serveur
        const response = await axios.post(host.Backend+'AnneeAcademique/', [formData]);
        

      } catch (error) {
        // Gérez les erreurs ici
        console.error('Erreur lors de l\'envoi du formulaire :', error);
      }
    };

  return (
    <Modal
    show={Ouvrir}
    onHide={Fermer}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Creation d'une année académique</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleSubmit}>
    <Modal.Body>
    <div className="modal-body">
  <div className="row">
    <div className="col mb-3">
      <label htmlFor="niveau" className="form-label">Niveau</label>
      <select className='form-select' value={formData.niveau_id} onChange={(e) => {
        handleChange(e);
      }} name='niveau_id'>
        <option value={null}>Choisir...</option>
        {P_AllNiveau.map((niveau) => (
          <option key={niveau.id} value={niveau.id}>
            {niveau.nom}
          </option>
        ))}
      </select>
    </div>
    <div className="col mb-3">
      <label htmlFor="classe" className="form-label">Anne Academique</label>
      <select className='form-select' name='annee_academique' onChange={(e) => {
        handleChange(e);
      }} required>
        <option value={null}>Choisir...</option>
        {annees}
      </select>
    </div>
  </div>

  <div className="row">
    <div className="col mb-3">
      <label htmlFor="classe" className="form-label">Elle debute ?</label>
      <input type='date' name='debut' onChange={(e) => {
        handleChange(e);
      }} className='form-control' placeholder='' />
    </div>

    <div className="col mb-3">
      <label htmlFor="classe" className="form-label">Et se termine ?</label>
      <input type='date' name='fin' onChange={(e) => {
        handleChange(e);
      }} className='form-control' placeholder='' />
    </div>
  </div>
</div>

                  
                  
    </Modal.Body>
    <Modal.Footer>
    <button  onClick={Fermer} type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                  Fermer
                </button>
                <button type="submit" class="btn btn-primary">Envoyer</button>
    </Modal.Footer>
    </form>
  </Modal>
  )
}
<>


</>