import React, { useState } from 'react'
import EnregistrementAdm from './Administrateurs/EnregistrementAdm';
import AdministrateursTable from './Administrateurs/AdministrateursTable';

export default function Administrateurs({handleView_ADM}) {

  const [isEnregistrement , setisEnregistrement]= useState(false);
  const enregistrement= ()=>{setisEnregistrement(true)}
  const close_enregistrement= ()=>{setisEnregistrement(false)}
  return (
    <div> 
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Administrateurs</h4>

<p>
Optimisez la gestion enseignante : planning des cours, évaluations, communication avec les élèves, suivi individuel, ressources pédagogiques... Simplifiez chaque aspect de l'enseignement.
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={enregistrement} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person-plus"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Enregistrer un Administrateur</p>
      </div>
    </div>
 
{isEnregistrement && 
<EnregistrementAdm
Ouvrir={enregistrement}
Fermer = {close_enregistrement}
></EnregistrementAdm>}
    
<AdministrateursTable handleView_ADM={handleView_ADM}></AdministrateursTable>
  </div>
</div>

  )
}
