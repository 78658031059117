import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import host from '../../../../host';
export default function Niveau({Ouvrir, Fermer}) {
  const [formData, setFormData] = useState({

    nom: ''

  });
  const [alert, setAlert] = useState(null);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Utilisez Axios pour envoyer les données du formulaire au serveur
      const response = await axios.post(host.Backend+'Niveau/', [formData]);
      
      switch (response.status) {
        case 201:
          setAlert({ type: 'success', message: 'Niveau créé avec succès!' });
          break;
        case 400:
          setAlert({ type: 'danger', message: 'Format de texte invalide ou le niveau existe déjà' });
          break;
        default:
          setAlert({ type: 'danger', message: 'Erreur inattendue lors de la création du niveau' });
          break;
      }
    } catch (error) {
      // Gérez les erreurs ici
      console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
  };
  
  return (
    <Modal
    show={Ouvrir}
    onHide={Fermer}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title> Intégrez un Nouveau Niveau</Modal.Title>
    </Modal.Header>
      <form onSubmit={handleSubmit}>
    <Modal.Body>
    <div class="modal-body">
                <div class="row">
                {alert && (
        <div className={`alert alert-${alert.type}`} role="alert">
          {alert.message}
        </div>
      )}
                  <div class="row">
                  <div class="col mb-3">
                    <label for="classe" class="form-label">Niveau</label>
                    <input type='text' className='form-control' name='nom' onChange={handleChange}   placeholder='nom du niveau' />
                  </div>
                  </div>
                  
               </div>
              </div>
                  
    </Modal.Body>
    <Modal.Footer>
    <button  onClick={Fermer} type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                  Fermer
                </button>
                <button type="submit" class="btn btn-primary">Ajouter</button>
    </Modal.Footer>
    </form>
  </Modal>
  )
}
