
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ListeParClasse from './Rapport/ListeParClasse';
import ReleveNotes from './Rapport/ReleveNote';

const authToken = localStorage.getItem('authToken');
export default function Rapport1() {


const [Lclasse , setLclasse] = useState(false)
const [Rnote , setRnote] = useState(false)
const [Cerf , setCerf] = useState(false)
const [FicheR , setFicheR] = useState(false)
const [Bulletin , setBulletin] = useState(false)
const [BilanS , setBilanS] = useState(false)
const [BilanA , setBilanA] = useState(false)
const [Carte , setCarte] = useState(false)
const [LEnsei , setLEnsei] = useState(false)
const [LAdm , setLAdm] = useState(false)
const [Em , setEm] = useState(false)
const [Etape , setEtape] = useState(0);


const Close = () => {
  setEtape(0);
  setRnote(false);
  setLclasse(false);
  setCerf(false)
  setFicheR(false)
  setBulletin(false)
  setBilanS(false)
  setBilanA(false)
  setCarte(false)
  setLEnsei(false)
  setLAdm(false)
  setEm(false)
}
  const listeclasse = () => {
    setEtape(1);
    setLclasse(true);
  }
  const relevenotes = () => {
    setRnote(true);
  }
  const cerf = () => {
    setEtape(3);
    setCerf(true);
  }
  const ficheR = () => {
    setEtape(4);
    setCerf(true);
  }
  const bulletin = () => {
    setEtape(5);
    setCerf(true);
  }
  const bilanS = () => {
    setEtape(6);
    setCerf(true);
  } 
  const bilanA = () => {
    setEtape(7);
    setCerf(true);
  } 
  const carte = () => {
    setEtape(7);
    setCarte(true);
  } 
  const lEnsei = () => {
    setEtape(7);
    setLEnsei(true);
  } 
  const lAdm = () => {
    setEtape(7);
    setLAdm(true);
  } 
  const em = () => {
    setEtape(7);
    setEm(true);
  } 
  const Etapes = () => {
    switch (Etape) {
      case 0:
        return (
          <div></div>
        );
      case 1:
        return (
          <ListeParClasse Ouvrir={listeclasse} Fermer={() =>{Close()}} />
        );
        case 2:
        return (
          <ReleveNotes Ouvrir={relevenotes} Fermer={() =>{Close()}} />
        ) ;
        case 3:
        return (
          <ReleveNotes Ouvrir={relevenotes} Fermer={() =>{Close()}} />
        ) ;
    }
  };


 


  return (
    <div> 
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Rapport</h4>

<p>
Explorez votre série de documents administratifs automatisés.
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={listeclasse} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-person-lines-fill mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Liste par classe</p>
      </div>
    </div>
    <div onClick={relevenotes} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-list-columns"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Réleve des notes</p>
      </div>
    </div>
    <div onClick={cerf} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-card-text"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Certificat de scolarité</p>
      </div> 
    </div>
    <div onClick={ficheR} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-list-ol"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Fiche de relevé</p>
      </div>
    </div>
    <div onClick={listeclasse} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-file-earmark-break"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Bulletin des notes</p>
      </div>
    </div>
    <div onClick={bilanS} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-percent"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Bilan semestriel</p>
      </div>
    </div>
    <div onClick={bilanA} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-calendar-week"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Bilan annuel</p>
      </div>
    </div>
    <div onClick={carte} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
      <i class="bi bi-person-vcard"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Carte scolaire</p>
      </div>
    </div>
    <div onClick={lEnsei} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-people mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Liste des enseignants</p>
      </div>
    </div>
    <div onClick={lAdm} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person-gear"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Liste des administrateurs</p>
      </div>
    </div>
    <div onClick={em} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-calendar mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Emplois du temps</p>
      </div>
    </div>
    <div onClick={em} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-upload mb-2"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Televersement</p>
      </div>
    </div>

    
  </div>




  <Etapes ></Etapes>


</div>


  )
}
