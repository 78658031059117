import { Button } from 'bootstrap';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Select from 'react-select'
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
const authToken = localStorage.getItem('authToken');

const Program = ({Ouvrir, Fermer }) => {
  const [step, setStep] = useState(1);
  const [selectedOption1, setselectedOption1] = useState([]);
  const [CoursOption , setCoursOption] = useState([]);
  const [InfoProgram , setInfoProgram] = useState([]);
  const [courseData, setcourseData] = useState([]);
  
  useEffect(() => {
  
    const fetchData = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:8000/Allcours/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  

        handleDataUpdate(response.data.info);
        setcourseData(response.data.info);
        console.log(courseData)
  
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);

      }
    };
  
    fetchData();
  }, []);  

  const handleDataUpdate = (newData) => {
    setcourseData(newData);

  };



  useEffect(() => {

    const options = courseData.map((element) => ({
      value: element.id,
      label: `${element.nom} ${element.prenom} ${element.matiere} ${element.classe} ${element.groupe}`
    }));
  
    setCoursOption(options);
  }, []); 



  const handleChange1= value => {
    setselectedOption1(value)
    setInfoProgram({ ...InfoProgram, 'id' : value.value })
  }
  
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfoProgram({ ...InfoProgram, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
   
   console.log(InfoProgram)
    try {
        const response = await axios.post('http://127.0.0.1:8000/Program/', InfoProgram);
        console.log(response.data);


    } catch (error) {
        console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
};


  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <form onSubmit={(e) => {e.preventDefault()}}>
            <div class="row mt-3">
      <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Cours</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"><i class="bx bx-user"></i></span>
       
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
       <Select value={selectedOption1} isSearchable={true}  onChange={(e) =>{handleChange1(e) }}  options={CoursOption}  />
      </div>
     </div>
   </div>

   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={handleSubmit} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </form>

        
        );
 
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Programation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default Program;




