import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import axios from 'axios';
import {
  MRT_BottomToolbar,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { Box, Button, IconButton } from '@mui/material';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
export default function Table_Classe({P_Classe}) {
  const [ClasseData, setClasseData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        const response = await axios.get(host.Backend+'Classe/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setClasseData(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
    const columns = useMemo(
        () => [
            {
              accessorKey: 'classe',
              header: 'Classe',
              size: 150,
            },
            {
              accessorKey: 'niveau',
              header: 'Niveau',
              size: 150,
            },
            {
              accessorKey: 'frais_scolarite',
              header: 'Scolarité',
              size: 150,
            },
          {
            accessorKey: 'create_at',
            header: 'Date d\'enregistrement',
            size: 150,
          },
        ],
        [],
      );
    
      const data = ClasseData ? ClasseData : [] ;

      return <MaterialReactTable 
      
      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering

      initialState={{ showColumnFilters: false, showGlobalFilter: true }}

      localization={MRT_Localization_FR}
      
      
    renderTopToolbarCustomActions={(props) => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '2px' }}>

        <IconButton 
          onClick={
            P_Classe
          }
          vari>
        <AddBoxIcon  />
        
      </IconButton>

        <IconButton
        onClick={() => {
          window.print();
        }}
      >
        <PrintIcon />
      </IconButton>
      </Box>
    )}


/>;
}
