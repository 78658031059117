import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import axios from 'axios';
import { Box, Button, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import host from '../../../../host';

const authToken = localStorage.getItem('authToken');
const user_id = localStorage.getItem('user_id');
const EnseignantTable = ({handleView_EN}) => {


  const [EnData , SetEndata] = useState(null)
  const [Loading , setLoading] = useState(false)
  useEffect(() => {
    const fetchDataa = async () => {
      try {
        setLoading(true);
  
        const response = await axios.get(host.Backend+'Enseignant/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        SetEndata(response.data);
        console.log(response)
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchDataa();
  }, []);
  // Existing data and columns definitions remain the same

  const columns = useMemo(
    () => [
      {
        accessorKey: 'photo_profil',
        header: 'Photo',
        size: 90,
        Cell: ({ row }) => (
          <img
            src={host.Backendout+`${row.original.photo_profil}`}
            alt="Photo"
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          />
        ),
      },
      {
        accessorKey: 'nom',
        header: 'Nom',
        size: 100,
      },
      {
        accessorKey: 'prenom',
        header: 'Prenom',
        size: 100,
      },
      {
        accessorKey: 'genre',
        header: 'Genre',
        size: 50,
      },
      {
        accessorKey: 'date_naissance',
        header: 'Date de naissance',
        size: 100,
      },
      {
        accessorKey: 'lieu_naissance',
        header: 'Lieu de naissance',
        size: 100,
      },
      {
        accessorKey: 'civilite',
        header: 'Nationnalité',
        size: 50,
      },
      {
        accessorKey: 'matricule',
        header: 'Matricule',
        size: 50,
      },
      {
        accessorKey: 'telephone1',
        header: 'Telephone 1',
        size: 50,
      },
      {
        accessorKey: 'telephone2',
        header: 'Telephone 2',
        size: 50,
      },
      {
        accessorKey: 'adresse',
        header: 'Adresse',
        size: 50,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 50,
      },
      {
        accessorKey: 'diplome',
        header: 'Diplome',
        size: 100,
      },
      {
        accessorKey: 'contrat',
        header: 'Contrat',
        size: 21,
      },
      {
        accessorKey: 'Create_at',
        header: 'Enregistré le',
        size: 100,
      },
      {
        accessorKey: 'Actions',
        header: 'Actions',
        size: 50,
        Cell: ({ row }) => (
          <Button variant="outlined"  onClick={(id) => {
            handleView_EN(row.original.id)
          
          }} 
          startIcon={<VisibilityIcon />}
        >
          </Button>
        ), 
      }
      
    ],
  
    [],
  );

  const handleButtonClick = (rowData) => {
    // Implement your functionality here using rowData from the clicked row
    console.log('Button clicked for row:', rowData);
  };

  const data = EnData ? EnData : [];

  const table = useMaterialReactTable({
    columns,
    data,
    localization:{MRT_Localization_FR},
  });
  return (

    <MaterialReactTable

      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering

      enableSelectAll={false}

      
    initialState= {{ columnVisibility: { photo_profil: false , email: false ,malade: false ,diplome: false ,contrat: false , adresse: false , telephone1: false , telephone2: false , Create_at : false , lieu_naissance : false} }}

      localization={MRT_Localization_FR}

    />

  );
};

export default EnseignantTable;
