import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import SchoolIcon from '@mui/icons-material/School';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
export default function ProfileEcole() {
  const [info , setInfo]= useState([])
  const [alertt, setAlertt] = useState(null);
  const [file, setFilePreview] = useState();

  const [formData, setFormData] = useState({

    'ecole': '',
    'adresse': '',
    'telephone': '',
    'email': '',
    'pays': '',
    'type': '',
    'arrete': '',
    'nif': '',
    'rccm': '',
    'code_postal':'',
    'site_web': '',
    'devise': '',
    'slogant': '',
    'enseignement': '',
    'logo': ''

});

const handleChange = (e) => {
  if (e.target.name === "logo") {
    const selectedFile = e.target.files[0];
    setFilePreview(URL.createObjectURL(selectedFile));
    setFormData({ ...formData, [e.target.name]: selectedFile });
} else {
    setFormData({ ...formData, [e.target.name]: e.target.value });
}
};
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'SchoolConfig/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setInfo(response.data[0]);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);


  useEffect(() => {
    setFormData({
      'ecole': info.ecole || '',
      'adresse': info.adresse || '',
      'telephone': info.telephone ||  '',
      'email': info.email ||  '',
      'pays': info.pays ||  '',
      'type': info.type ||  '',
      'arrete': info.arrete ||  '',
      'nif':  info.nif || '',
      'rccm': info.rccm ||  '',
      'code_postal': info.code_postal || '',
      'site_web': info.site_web ||  '',
      'devise': info.devise ||  '',
      'slogant':  info.slogant || '',
      'enseignement':  info.enseignement || '',
      'adresse': info.adresse ||  '',
      'logo': info.logo ||  ''
  
    });
  }, [info]);



 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
        formDataToSend.append(key, formData[key]);
    }

    console.log(formData);

    try {
        // Utilisez Axios pour envoyer les données du formulaire au serveur
        const response = await axios.post(host.Backend+'SchoolConfig/', formDataToSend);
        console.log(response.data);
        switch (response.status) {
            case 201:
                setAlertt({ type: 'success', message: 'Niveau créé avec succès!' });
                break;
            case 400:
                setAlertt({ type: 'danger', message: 'Format de texte invalide ou le niveau existe déjà' });
                break;
            default:
                setAlertt({ type: 'danger', message: 'Erreur inattendue lors de la création du niveau' });
                break;
        }

    } catch (error) {
        // Gérez les erreurs ici
        console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
};


  return (
    <div class="container">
<div class="row">
<div class="col-xl-12">
<div class="card">
    
<div class="card-body">


<form  onSubmit={handleSubmit}>
<ol class="activity-checkout mb-0 px-4 mt-3">
<li class="checkout-item">
<div class="avatar checkout-icon p-1">
<div class="avatar-title rounded-circle bg-primary">
<SchoolIcon className='font-size-20 text-white' ></SchoolIcon>
</div>
</div>
<div class="feed-item-list">

<h5 class="font-size-16 mb-1">Apropros de votre Ecole</h5>
<p class="text-muted text-truncate mb-4"></p>

<div class="card-body">
  <div class="d-flex align-items-start align-items-sm-center gap-4">
  
  <img src={file || host.Backend+`${info.logo}` }  alt="user-avatar" className="d-block rounded" height="100" width="100" id="uploadedAvatar" />

    <div class="button-wrapper">
      <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
        <span class="d-none d-sm-block">Changer de logo</span>
        <i class="bx bx-upload d-block d-sm-none"></i>
        <input type="file" hidden id="upload" class="account-file-input"  accept="image/png, image/jpeg" name='logo' onChange={handleChange} />
      </label>
      <button type="button" class="btn btn-outline-secondary account-image-reset mb-4">
        <i class="bx bx-reset d-block d-sm-none"></i>
        <span class="d-none d-sm-block">Restorer</span>
      </button>

      <p class="text-muted mb-0">Seul JPG ou PNG sont Accepte. Taille Max 800KO</p>
    </div>
  </div>
</div>
<div class="mb-3">
<div class="row">
<div class="col-lg-6">
<div class="mb-3">
<label class="form-label" for="billing-name">Nom de l'Ecole</label>
<input type="text" className="form-control"  name='ecole' value={formData.ecole}  onChange={handleChange} />
</div>
</div>
<div class="col-lg-6">
<div class="mb-3">
<label class="form-label" for="billing-email-address">Adresse</label>
<input type="text" class="form-control" id="billing-email-address" value={formData.adresse} name='adresse' onChange={handleChange} />
</div>
</div>
<div class="col-lg-6">
<div class="mb-3">
<label class="form-label" for="billing-phone">Telephone</label>
<input type="text" class="form-control" id="billing-phone"  value={formData.telephone}  name='telephone' onChange={handleChange} />
</div>
</div>
<div class="col-lg-6">
<label class="form-label" for="billing-address">Email</label>
<input type="email" class="form-control" id="billing-phone"  value={formData.email} name='email' onChange={handleChange} />
</div>
<div class="col-lg-6">
<div class="mb-4 mb-lg-0">
<label class="form-label">Pays</label>
<select class="form-control form-select" title="Country" name='pays' onChange={handleChange} value={formData.pays} required>
  
<option >Niger</option>
<option >Mali</option>
<option >Burkina Faso</option>
<option >RCI</option>
<option >Togo</option>
<option >Benin</option>
<option >Nigeria</option>
</select>
</div>
</div>

<div class="col-lg-6">
<div class="mb-4 mb-lg-0">
<label class="form-label">Type</label>
<select class="form-control form-select" title="Country" name='type' onChange={handleChange} required>
  <option value={formData.type}>{formData.type}</option>
  <option value="Privee">Privee</option>
  <option value="Public">Public</option>
</select>

</div>
</div>
<div class="col-lg-6">
<div class="mb-4 mb-lg-0">
<label class="form-label">Enseignement</label>
<select class="form-control form-select" title="Country" name='enseignement' onChange={handleChange} required>
  <option value={formData.enseignement}>{formData.enseignement}</option>
<option value="Generale">Generale</option>
<option value="Professionnel">Professionnel</option>
</select>
</div>
</div>


<div class="mb-3">
<label class="form-label" for="billing-address">Arrete</label>
<textarea class="form-control" id="billing-address" rows="3" value={formData.arrete} name='arrete' onChange={handleChange} ></textarea>
</div>
<div class="row">
<div class="col-lg-6">
<div class="mb-4 mb-lg-0">
<label class="form-label" for="billing-city">NIF</label>
<input type="text" class="form-control" id="billing-city" value={formData.nif} name='nif' onChange={handleChange} />
</div>
</div>
<div class="col-lg-6">
<div class="mb-4 mb-lg-0">
<label class="form-label" for="billing-city">rccm</label>
<input type="text" class="form-control" id="billing-city" value={formData.rccm} name='rccm' onChange={handleChange} />
</div>
</div>

<div class="col-lg-6">
<div class="mb-0">
<label class="form-label" for="zip-code">Zip / Postal code</label>
<input type="text" class="form-control" id="zip-code" value={formData.code_postal} />
</div>
</div>

<div class="col-lg-6">
<div class="mb-0">
<label class="form-label" for="zip-code">Site web</label>
<input type="text" class="form-control" id="zipde" value={formData.site_web} name='site_web' onChange={handleChange} />
</div>
</div>

<div class="col-lg-6">
<div class="mb-0">
<label class="form-label" for="zip-code">Devise</label>
<input type="text" class="form-control" id="" value={formData.devise} name='devise' onChange={handleChange} />
</div>
</div>

<div class="col-lg-6">
<div class="mb-0">
<label class="form-label" for="zip-code">Slogant</label>
<input type="text" class="form-control" id="zip-code" value={formData.slogant} name='slogant' onChange={handleChange} />
</div>
</div>




</div>
</div>
<div class="row my-4">
<div class="col">
<div class="text-end mt-2 mt-sm-0">
<button type='submit' class="btn btn-success">Enregistrer </button>
</div>
</div> 
</div> 
</div>
</div>
</li>


</ol>

</form>
</div>
</div>

</div>

</div>

</div>
  )
}














