import React from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
const EmmergementTable = () => {

  // Existing data and columns definitions remain the same

  const columns = useMemo(
    () => [
      
        {
        accessorKey: 'Enseignant',
        header: 'Enseignant',
        size: 150,
        },
        {
        accessorKey: 'Course',
        header: 'Matiere',
        size: 150,
      },
      {
        accessorKey: 'Classe',
        header: 'Classe',
        size: 150,
      },
      {
        accessorKey: 'Groupe',
        header: 'Groupe',
        size: 200,
      },
      {
        accessorKey: 'Title',
        header: 'Titre du cours',
        size: 150,
      },
      {
        accessorKey: 'Durre',
        header: 'Durree',
        size: 150,
      },
      {
        accessorKey: 'Date-Emmergement',
        header: 'Date Emmergement',
        size: 150,
      },
      {
        accessorKey: 'AnneeAcademique',
        header: 'Annee Academique',
        size: 150,
      },
      {
        accessorKey: 'Sermestre',
        header: 'Heure',
        size: 150,
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        size: 150,
      }
    ],
    [],
  );

  const handleButtonClick = (rowData) => {
    // Implement your functionality here using rowData from the clicked row
    console.log('Button clicked for row:', rowData);
  };

  const data = [
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    localization:{MRT_Localization_FR},
  });
  return (

    <MaterialReactTable

      columns={columns}

      data={data}

      enableColumnFilterModes

      enableColumnOrdering

      enableEditing

      enableColumnPinning

      enableRowActions

      enableRowSelection

      enableSelectAll={false}

      initialState={{ showColumnFilters: false, showGlobalFilter: true }}

      localization={MRT_Localization_FR}

    />

  );
};

export default EmmergementTable;
