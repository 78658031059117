import zIndex from '@mui/material/styles/zIndex';
import React, { useState, useEffect } from 'react';

const Nav = ({Menu, User, DashData}) => {
const  [isDropdown , setDropdown] = useState(false);



  return (
    <nav
               className="layout-navbar container-xxl navbar navbar-expand-xl d-flex navbar-detached align-items-center bg-navbar-theme"
               id="layout-navbar"
            >
              <div className="d-flex align-items-center  w-100">
               <div className="layout-menu-toggle navbar-nav d-flex align-items-xl-center me-3 me-xl-0 d-xl-none">
                 <a onClick={Menu} className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                   <i className="bx bx-menu bx-sm"></i>
                 </a>
               </div>
   
               <div className="navbar-nav-right d-flex align-items-center mt-navbar-nav-right d-flex align-items-center mt-0 ms-auto" id="navbar-collapse">
   
                 <div className="navbar-nav align-items-center">
                   <div className="nav-item d-flex align-items-center">
                     <i className="bx bx-search fs-4 lh-0"></i>
                     <input
                       type="text"
                       className="form-control border-0 shadow-none"
                       placeholder="Recherche Intelligente..."
                       aria-label="Search..."
                     />
                   </div>
                 </div>
                 <ul class="navbar-nav flex-row align-items-center ms-auto">
   
   
                 <li class="nav-item lh-1 me-3">
                  <a class="github-button">Pro + </a>
                </li>
   
                   <li class="nav-item navbar-dropdown dropdown-user dropdown">
                     <a onClick={ ()=>{setDropdown(!isDropdown)}} class="nav-link dropdown-toggle hide-arrow"  data-bs-toggle="dropdown">
                       <div class="avatar avatar-online">
                         <img src="../assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
                       </div>
                     </a>
                     <ul className={`dropdown-menu dropdown-menu-end ${isDropdown && "d-block"} `}>
                       <li>
                         <a class="dropdown-item" href="#">
                           <div class="d-flex">
                             <div class="flex-shrink-0 me-3">
                               <div class="avatar avatar-online">
                                 <img src="../assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
                               </div>
                             </div>
                             <div class="flex-grow-1">    
                     <span class="fw-semibold d-block">{User && User.nom}</span>   
                      <small class="text-muted">Admin</small>
                             </div>
                           </div>
                         </a>
                       </li>
                       <li>
                         <div class="dropdown-divider"></div>
                       </li>
                       <li>
                         <a class="dropdown-item" href="#">
                           <i class="bx bx-user me-2"></i>
                           <span class="align-middle">Mon Profile</span>
                         </a>
                       </li>
                       <li>
                         <a class="dropdown-item" href="#">
                           <i class="bi bi-phone me-2"></i>
                           <span class="align-middle">Appeler</span>
                         </a>
                       </li>
                       <li>
                         <a class="dropdown-item" href="#">
                           <span class="d-flex align-items-center align-middle">
                             <i class="flex-shrink-0 bi bi-chat me-2"></i>
                             <span class="flex-grow-1 align-middle">Messages</span>
                             <span class="flex-shrink-0 badge badge-center rounded-pill bg-success w-px-20 h-px-20">4</span>
                           </span>
                         </a>
                       </li>
                       <li>
                         <a class="dropdown-item" href="#">
                           <span class="d-flex align-items-center align-middle">
                             <i class="flex-shrink-0 bi bi-bell me-2"></i>
                             <span class="flex-grow-1 align-middle">Notifications</span>
                             <span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                           </span>
                         </a>
                       </li>

                       <li>
                         <a class="dropdown-item" href="#">
                           <i class="bx bx-cog me-2"></i>
                           <span class="align-middle">Reglage</span>
                         </a>
                       </li>

                       <li>
                         <div class="dropdown-divider"></div>
                       </li>
                       <li>
                         <a class="dropdown-item" href="auth-login-basic.html">
                           <i class="bx bx-power-off me-2"></i>
                           <span class="align-middle">Se Deconnecter</span>
                         </a>
                       </li>
                     </ul>
                   </li>
   
                 </ul>
               </div>
               </div>
             </nav>
   
    );
}

export default Nav;
