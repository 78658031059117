import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { Avatar, CircularProgress } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import {  useEffect, useState } from 'react'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import Select from 'react-select'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import moment from 'moment';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');
export const ENProfil = ({id}) => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleButtonClick = (newValue) => {
        setValue(newValue);
      };
      const [ENData, setENData] = useState([]);
      const [Attribution, setAttribution] = useState([]);
      useEffect(() => {
      
        const fetchDataa = async () => {
          try {
      
            const response = await axios.get(host.Backend+`EnseignantProfil/${id}/`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
              },
            });
      
            setENData(response.data.enseignant);
            setAttribution(response.data.attribution)
          } catch (error) {
            console.error('Erreur lors de la requête API:', error);
          } 
        };
      
        fetchDataa();
      }, []);
  return (
    

<div class="container">
    <div class="row">
        <div class="col-xl-8">
            <div className="card">
              <div className="card-body pb-0">
                <div className="row align-items-center">
                  <div className="col-md-3">
                    <div className="text-center ">
                      <img
                        src=""
                        className="img-fluid avatar-xxl rounded-circle"
                        alt=""
                      />
                                          <Avatar
        alt="Remy Sharp"
        src={host.Backend+`${ENData.photo_profil}`}
        sx={{ width: 150, height: 150 }}
      />
                      <h6 className="text-primary font-size-12 mt-3 mb-2">{ENData.nom ? ENData.nom : ''}  &nbsp;{ENData.nom ? ENData.prenom : ''}</h6>
                      <p className="text-muted mb-0">Enseignant</p>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="ms-3">
                      <div>
                        <h4 className="card-title mb-2">Profil Enseignant</h4>

      <ListGroup>
     <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Matricule</div>
          {ENData.nom ? ENData.matricule : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Telephone</div>
          {ENData.nom ? ENData.telephone1 : ''} / {ENData.nom ? ENData.telephone2 : ''}
        </div>
      </ListGroup.Item>

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Adresse</div>
          {ENData.nom ? ENData.adresse : ''}
        </div>
      </ListGroup.Item>

      </ListGroup>
                      </div>
                      <ul className="nav nav-tabs nav-tabs-custom border-bottom-0 mt-3 nav-justfied" role="tablist">
                      <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                            m: 1,
                            },
                        }}
                        >
                        <ButtonGroup variant="text" aria-label="Basic button group">
                            <Button  onClick={() => handleButtonClick('1')}>Cours</Button>
                            <Button  onClick={() => handleButtonClick('2')}>Emploi du temps</Button>
                            <Button  onClick={() => handleButtonClick('3')}>Statistiques</Button>
                        </ButtonGroup>
                        </Box>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


        <div className="card">
            <div className="tab-content p-4">
                <div className="tab-pane active show" id="team-tab" role="tabpanel">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <TabPanel value="1">
                    <h4 className="card-title mb-4">Cours</h4>

      
          <Table responsive="sm" striped bordered>
            <thead>
              <tr>
                <th>Matiere</th>
                <th>classe - groupe</th>
                <th>AC</th>
                <th>DT</th>
              </tr>
            </thead>
            <tbody>
              {Attribution.map((Att) => (
                <tr key={Att.matiere}>
                  <td>{Att.matiere}</td>
                  <td>{Att.classe} {Att.groupe}</td>
                  
                  <td>{Att.annee_academique}</td>
                  <td>{moment(Att.create_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
     
           </TabPanel>
        <TabPanel value="2">
                    <h4 className="card-title mb-4">Horaire de cours</h4>
    
        </TabPanel>
        <TabPanel value="3">
                    <h4 className="card-title mb-4">Statistiques</h4>




        </TabPanel>
      </TabContext>
    </Box>

        </div>
            </div>
                </div>
                </div>

 
                <div className="col-xl-4 col-md-6" id="team-4">
  <div className="card">
    <div className="card-body">

        <h5 className="mb-1 font-size-17">Informations sur l'Enseignant</h5>
        <p className="text-muted font-size-13 mb-0"></p>
        <ListGroup as="ol" >

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Genre</div>
          {ENData.nom ? ENData.genre : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Date de naissance</div>
          {ENData.nom ? ENData.date_naissance : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Lieu de naissance</div>
          {ENData.nom ? ENData.lieu_naissance : ''}
        </div>
      </ListGroup.Item>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Civilite</div>
          {ENData.nom ? ENData.civilite : ''}
        </div>
      </ListGroup.Item>
  
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Diplome</div>
          
          {ENData.nom ? ENData.diplome : ''} : {ENData.nom ? ENData.contrat : ''}
        </div>
      </ListGroup.Item>

      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Autre Info</div>
          
        </div>
      </ListGroup.Item>

      </ListGroup>
    </div>
    </div>
  </div>



</div>
</div>
  )
}
