import { Button } from 'bootstrap';
import React, { useMemo, useState, useEffect } from 'react';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import countryList from 'react-select-country-list'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import axios from 'axios';
import host from '../../../../host';
const authToken = localStorage.getItem('authToken');


const EnregistrementCours = ({Ouvrir, Fermer}) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [CoursInfo, setCoursInfo] = useState({ niveau_id: '' , enseignant_id: '', matiere_id: '' , groupe_id: '', annee_academique_id: '' , renumeration: '' ,par: '' });
  const [selectedOption1, setselectedOption1] = useState([]);
  const [EnseignantOption, setEnseignantOption] = useState([]);
  const [ClasseAproprie , setClasseAproprie] = useState([])
  const [AllNiveau , setAllNiveau] = useState([])
  const [Enseignantobj , setEnseignantobj] = useState([])
  const [MatiereAproprie , setMatiereAproprie] = useState([])
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'AllNiveau/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setAllNiveau(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);
  useEffect(() => {
  
    const fetchDataa = async () => {
      try {
  
        const response = await axios.get(host.Backend+'Enseignant/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });
  
        setEnseignantobj(response.data);
      } catch (error) {
        console.error('Erreur lors de la requête API:', error);
      } 
    };
  
    fetchDataa();
  }, []);
  
  const  handleupClasse = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Classebyniveau/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setClasseAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };

  const  handleupMatiere = async (e) => {

    try {

      const response = await axios.get(host.Backend+`Matierebyclasse/${e.target.value}/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
      });

      setMatiereAproprie(response.data);
    } catch (error) {
      console.error('Erreur lors de la requête API:', error);
    }


};
  useEffect(() => {

    const options = Enseignantobj.map((element) => ({
      value: element.id,
      label: `${element.nom} ${element.prenom} ${element.matricule}`
    }));
  
    setEnseignantOption(options);
    
  }, []); 
  
  const [GroupeAproprie , setGroupeAproprie] = useState([])

  const  handleupGroupe = async (e) => {

        try {
    
          const response = await axios.get(host.Backend+`Groupebyclasse/${e.target.value}/`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${authToken}`
            },
          });
    
          setGroupeAproprie(response.data);
        } catch (error) {
          console.error('Erreur lors de la requête API:', error);
        } 
    

  };








  const handleChange1= value => {
    setselectedOption1(value)
    setCoursInfo({ ...CoursInfo, 'enseignant_id' : value.value })
  }





  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoursInfo({ ...CoursInfo, [name]: value });
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
  
     try {
         const response = await axios.post(host.Backend+'MatiereAttribution/', CoursInfo);
         console.log(response.data);
 
 
     } catch (error) {
         console.error('Erreur lors de l\'envoi du formulaire :', error);
     }

  };

  const RenderForm = () => {
    switch (step) {
      case 1:
        return (
            <>
            <div class="row mt-3">

            <div class="mb-3 col-md-12">
    <label for="classe" class="form-label">Niveau</label>
                  <select className='form-select' 
  onChange={(e) => {
    handleChange(e);
    handleupClasse(e);
  }} name='niveau_id'  value={CoursInfo.niveau_id}>
                        <option value={null}>Chosir...</option>   
                          {AllNiveau.map((niveau) => (
                              <option key={niveau.id} value={niveau.id}>
                                {niveau.nom}
                              </option>
                          ))}
                          </select>
     </div>
   

   <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Classe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
                          <select className='form-select'  name='classe_id' onChange={(e) => {
    handleChange(e);
    handleupGroupe(e);
    handleupMatiere(e);
  }} value={CoursInfo.classe_id} required>
                    <option value={null}>Chosir...</option> 
                      {ClasseAproprie.map((classe) => (
                        <option value={classe.id}>{classe.classe}</option>
                      ))}
                    </select>
     </div>
   </div>


   <div class="mt-3 col-md-12 d-flex justify-content-center">
     <span  onClick={nextStep} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </>

        
        );
        case 2:
        return (
            <>
            <div class="row mt-3">
            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Matiere</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span>
       <select className='form-select'  name='matiere_id' onChange={(e) => {
    handleChange(e);
  }} value={CoursInfo.matiere_id} required>
                    <option value={null}>Chosir...</option> 
                      {MatiereAproprie.map((matiere) => (
                        <option value={matiere.id}>{matiere.matiere}</option>
                      ))}
                    </select>
     </div>
   </div>


   <div class="mt-3 col-md-12 d-flex justify-content-center">
   <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={nextStep} class="btn btn-primary ">Suivant</span>
   </div>
   </div>
            </>

        
        );
      case 3:
        return (
            <>
            <div class="row mt-3">
            <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Groupe</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i
       ></span>                          
       <select className='form-select'  name='groupe_id' onChange={(e) => {
    handleChange(e);
  }} value={CoursInfo.groupe_id} required>
                    <option value={null}>Chosir...</option> 
                      {GroupeAproprie.map((groupe) => (
                        <option value={groupe.id}>{groupe.groupe}</option>
                      ))}
                    </select>
     </div>
   </div>

    <div class="mb-3 col-md-12">
     <label class="form-label" for="basic-icon-default-fullname">Enseignant</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       
       <div className='form-control bg-white' style={{ background:'#fff'  }} >
       <Select value={selectedOption1} isSearchable={true}  onChange={(e) =>{handleChange1(e) }}  options={EnseignantOption}  />
      </div>
   </div>


   <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-fullname">Renumeration</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
         <input
           type="number"
           class="form-control"
           id="basic-icon-default-fullname"
           name="renumeration"
           value={CoursInfo.renumeration}
           onChange={handleChange}
         />
     </div>
   </div>

   <div class="mb-3 col-md-6">
     <label class="form-label" for="basic-icon-default-fullname">Frequence</label>
     <div class="input-group input-group-merge">
       <span id="basic-icon-default-fullname2" class="input-group-text"
         ><i class="bx bx-user"></i></span>
       <select className='form-select' 
         name="par"
         value={CoursInfo.par}
         onChange={handleChange}>
            <option value={'H'}>Heure</option>
            <option value={'J'}>jour</option>
            <option value={'M'}>Mois</option>
       </select>
     </div>
   </div>



   </div>
   


   
     <div class="mt-3 col-md-12 d-flex justify-content-center ">
     <span onClick={prevStep} class="btn btn-outline-secondary me-2">Precedent</span>
     <span  onClick={handleSubmit} class="btn btn-primary me-2 ">Envoyer</span>
   </div>
</div>
            </>
        );

        case 4:
        return (


      <div class="card-body mt-3">
        <div class="mb-3  col-12 mb-0">
          <div class="alert alert-success">
            <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
            <p class="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
          </div>
        </div>

      </div>
            
        );
      default:
        return null;
    }
  };

  return (
    <Modal
        show={Ouvrir}
        onHide={Fermer}
        backdrop="static"
        keyboard={false}
      >

<Modal.Header closeButton>
          <Modal.Title>Cours</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {RenderForm()}
      </Modal.Body>
    </Modal>
  );
};

export default EnregistrementCours;




