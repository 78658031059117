import React, { useState , useEffect } from 'react'
import axios from 'axios';
import FraisScolarites from './FraisScolarite';
import Payements from './Payements';
import { EleveScolarite } from '../View/EleveScolarite';
import { ENPaye } from '../View/ENPaye';
const authToken = localStorage.getItem('authToken');


export default function Comptabilite({}) {
  const [isFraisScolarite , setisFraisScolarite ] = useState(false);
  const [isViewScolarite , setisViewScolarite ] = useState(false);
  const [isViewEN , setisViewEN ] = useState(false);
  const [ViewId , setViewId ] = useState();
  const [payement , setpayement] = useState([])
  const [Accueil , setAccueil ] = useState(true);


function closeAll1(){
    setisFraisScolarite(false);
    setispayement(false);
    setAccueil(true)
}

 const open = () =>{
    closeAll1()
    setisViewEN(false)
    setisFraisScolarite(true)

 }
 const close = () =>{
    closeAll1()

 }

 const [ispayement , setispayement ] = useState(false);
 const Payement = () =>{
    closeAll1()
    setisViewScolarite(false)
    setispayement(true)

 }
 const close_payement = () =>{
    closeAll1()

 }
  
  return (
    <> 
 {Accueil &&    <>
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Comptabilite</h4>

<p>
Centralisez la gestion des élèves : profils, notes, absences, dossiers individuels, communication parentale... Tout ce dont vous avez besoin, à portée de clic.
</p>

    <div class="d-flex flex-wrap" id="icons-container">
    <div onClick={open} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person-plus"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Frais de scolarite</p>
      </div>
    </div>
    <div  onClick={Payement} class="card icon-card cursor-pointer text-center mb-4 mx-2">
      <div class="card-body">
        <i class="bi bi-person-plus"></i>
        <p class="icon-name text-capitalize text-truncate mb-0">Paye Enseignant</p>
      </div>
    </div>
  </div>
  </> }

{isFraisScolarite && 
<FraisScolarites
Ouvrir={open}
Fermer = {close}
setisViewScolarite = {setisViewScolarite}
setViewId = {setViewId}
></FraisScolarites>}

{ispayement && 
<Payements
Ouvrir={Payement}
Fermer = {close_payement}
setisViewEN = {setisViewEN}
setViewId = {setViewId}
></Payements>}

{isViewScolarite &&  <EleveScolarite id ={ViewId}  /> }
{isViewEN&&  <ENPaye id ={ViewId}  /> }
</>


  )
}
