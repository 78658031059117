import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import {
  MRT_BottomToolbar,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PreviewIcon from '@mui/icons-material/Preview';
import { Box, Button, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import host from '../../../../host';
export default function EleveTable ({handleView, Eleve, Preins}){
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'photo_profil',
        header: 'Photo',
        size: 90,
        Cell: ({ row }) => (
          <img
            src={host.Backendout+`${row.original.photo_profil}`}
            alt="Photo"
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          />
        ),
      },
      {
        accessorKey: 'nom',
        header: 'Nom',
        size: 100,
      },
      {
        accessorKey: 'prenom',
        header: 'Prenom',
        size: 100,
      },
      {
        accessorKey: 'genre',
        header: 'Genre',
        size:50,
      },
      {
        accessorKey: 'date_naissance',
        header: 'Date de naissance',
        size: 50,
      },
      {
        accessorKey: 'lieu_naissance',
        header: 'Lieu de naissance',
        size: 50,
      },
      {
        accessorKey: 'civilite',
        header: 'Nationnalité',
        size: 50,
      },
      {
        accessorKey: 'matricule',
        header: 'Matricule',
        size: 50,
      },
      {
        accessorKey: 'telephone1',
        header: 'Telephone 1',
        size: 50,
      },
      {
        accessorKey: 'telephone2',
        header: 'Telephone 2',
        size: 50,
      },
      {
        accessorKey: 'adresse',
        header: 'Adresse',
        size: 50,
      },
      {
        accessorKey: 'responsable',
        header: 'Responsable',
        size: 100,
      },
      {
        accessorKey: 'adresse_responsable',
        header: 'Adresse du respon..',
        size: 100,
      },
      {
        accessorKey: 'is_malade',
        header: 'Est t-il malade ?',
        size: 50,
      },
      {
        accessorKey: 'malade',
        header: 'Maladi',
        size: 150,
      },
      {
        accessorKey: 'groupe_sanguin',
        header: 'Groupe sanguin',
        size: 50,
      },
      {
        accessorKey: 'create_at',
        header: 'Enregistré le',
        size: 100,
      },{
        accessorKey: 'Actions',
        header: 'Actions',
        size: 50,
        Cell: ({ row }) => (
          <Button variant="outlined"  onClick={(id) => {
            handleView(row.original.id)
          
          }} 
          startIcon={<VisibilityIcon />}
        >
          </Button>
        ),        
      },

      
    ],
    [],
  );

  const data = Eleve ? Eleve : []
  const handleDownloadRows = (selectedRows) => {
    console.log('Selected Rows:', selectedRows);
  };

  const togglePreview = () => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.length > 0 ? [] : selectedRows
    );
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const exportColumns = [
      'nom',
      'prenom',
      'genre',
      'date_naissance',
      // ... ajoutez d'autres colonnes selon vos besoins
    ];
  
    // Filtrer les données pour inclure uniquement les colonnes sélectionnées
    const filteredTableData = rows.map((row) => {
      const filteredRow = {};
      exportColumns.forEach((column) => {
        filteredRow[column] = row.original[column];
      });
      return Object.values(filteredRow);
    });
  
    // Utilisez les noms des colonnes sélectionnées comme en-têtes
    const tableHeaders = exportColumns.map((column) => columns.find((c) => c.accessorKey === column).header);

    autoTable(doc, {
      head: [tableHeaders],
      body: filteredTableData,
    });
  
    doc.save('Eleve.pdf');
  };
const [Unit_var, setUnit_var] = useState({}) 


 
  return (

    <MaterialReactTable
    columns={columns}
    data={data}
    enableColumnFilterModes
    enableColumnOrdering
    enableColumnPinning
    initialState= {{ columnVisibility: { photo_profil: false , groupe_sanguin: false ,malade: false ,is_malade: false ,adresse_responsable: false , adresse: false , telephone1: false , telephone2: false , responsable: false , create_at : false , lieu_naissance : false} }}

    localization={MRT_Localization_FR}
    className="-striped -highlight"
    renderTopToolbarCustomActions={({ table }) => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '2px' }}>

        <IconButton 
          onClick={
            Preins
          }
          vari>
        <AddBoxIcon  />
        
      </IconButton>

      </Box>
    )}

  />

  );
};


